import {
    RESET_HOUSING_PERIOD_STATE,
    CLEAR_HOUSING_PERIOD,
    SET_HOUSING_PERIOD_BASIC_DETAILS,
    DELETE_HOUSING_PERIOD,
    DELETE_HOUSING_PERIOD_SUCCESS,
    DELETE_HOUSING_PERIOD_ERROR,
    ENABLE_DISABLE_HOUSING_PERIOD,
    ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS,
    ENABLE_DISABLE_HOUSING_PERIOD_ERROR,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS,
    LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR,
    SEARCH_HOUSING_PERIODS,
    SEARCH_HOUSING_PERIODS_SUCCESS,
    SEARCH_HOUSING_PERIODS_ERROR,
    LOAD_HOUSING_PERIOD,
    LOAD_HOUSING_PERIOD_SUCCESS,
    LOAD_HOUSING_PERIOD_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS,
    SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR,
    SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP, 
    INITIALIZE_HOUSING_PERIOD_PROPERTIES,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS,
    INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR,
    GET_PROPERTIES_FOR_HOUSING_PERIOD,
    GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS,
    GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION,
    SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION,
    LOAD_HOUSING_PERIOD_APPLICATIONS,
    LOAD_HOUSING_PERIOD_APPLICATIONS_SUCCESS,
    LOAD_HOUSING_PERIOD_APPLICATIONS_ERROR,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_SUCCESS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_ERROR,
    SEARCH_HOUSING_PERIOD_APPLICATIONS,
    SEARCH_HOUSING_PERIOD_APPLICATIONS_SUCCESS,
    SEARCH_HOUSING_PERIOD_APPLICATIONS_ERROR,
    LOAD_HOUSING_PROPERTIES_SUCCESS,
    LOAD_HOUSING_PROPERTIES_ERROR,
    LOAD_HOUSING_PROPERTIES,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    ADD_HOUSING_PERIOD_PROPERTY_APPLICATION,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION,
    DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR,
    CLEAR_HOUSING_PERIOD_PROPERTY_LIST,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE,
    LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE,
    INITIALIZE_ROOM_ASSIGNMENT_PUBLISH_DATE,
    LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS,
    LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR,
    SET_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SET_MANAGE_ROOM_ASSIGNMENT_PUBLISH_DATE,
    EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE,
    DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SAVE_EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE,
    SET_HOUSING_PERIOD_BREADCRUMB,
    DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_SUCCESS,
    LOAD_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE_ERROR,
    INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGMENT_PUBLISH_DATE,
    LOAD_MANAGE_CHARGES,
    LOAD_MANAGE_CHARGES_SUCCESS,
    SAVE_MANAGE_CHARGES,
    LOAD_MANAGE_CHARGES_ERROR,
    LOAD_MANAGE_CHARGES_SELECT_ITEMS_SUCCESS,
    LOAD_MANAGE_CHARGES_SELECT_ITEMS_ERROR,
    SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS,
    SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_SUCCESS,
    SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_ERROR,
    ADD_HOUSING_PERIOD_PROPERTIES,
    SET_SELECTED_HOUSING_PERIOD_PROPERTY
} from "./housingPeriodsActionTypes";

import {
    CLEAR_HOUSING_PERIOD_PROPERTY,
    LOAD_HOUSING_PERIOD_PROPERTY,
    LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_ERROR,
    SAVE_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS,
    SAVE_HOUSING_PERIOD_PROPERTY_ERROR,
} from "../housing-period-property/housingPeriodPropertyActionTypes";

import { MANAGE_CHARGES_SUBSTEP } from "../../../pages/student-life-page/housing/housing-periods/housingPeriodConstants";
import {
    getApi,
    postApi,
    deleteApi
} from "../../../services/apiBase";
import { housingAPIs } from "../../../services/apiURL";
import { showDeleteSuccessToast, showEnableDisableSuccessToast, showUpdateSuccessToast, showAddSuccessToast } from '../../../utils/Message/toasts';
import {
    convertBooleanToYesNo,
    convertISODateTimeStringToShortDate
} from "../../../helper/formatUtils";
import { selectHousingPeriod } from "./housingPeriodsSelectors";


export const resetHousingPeriodState = () => async (dispatch) => {
    await dispatch({ type: RESET_HOUSING_PERIOD_STATE });
}

export const clearHousingPeriod = () => async (dispatch) => {
    await dispatch({
        type: CLEAR_HOUSING_PERIOD
    })
}

export const setHousingPeriodBasicDetails = (housingPeriod) => async (dispatch) => {
    dispatch({ type: SET_HOUSING_PERIOD_BASIC_DETAILS, payload: housingPeriod });
}

export const deleteHousingPeriod = (housingPeriodID) => async (dispatch) => {

    dispatch({ type: DELETE_HOUSING_PERIOD });

    try {
        await deleteApi(`${housingAPIs.housingPeriods.deleteHousingPeriod}/${housingPeriodID}`);
        dispatch({ type: DELETE_HOUSING_PERIOD_SUCCESS, payload: housingPeriodID });
        showDeleteSuccessToast();
    }
    catch (err) {
        dispatch({ type: DELETE_HOUSING_PERIOD_ERROR });
        console.error(err);
    }
}

export const enableDisableHousingPeriod = (housingPeriodID, originalIsActive) => async (dispatch) => {

    dispatch({ type: ENABLE_DISABLE_HOUSING_PERIOD });

    try {
        await postApi(housingAPIs.housingPeriods.toggleActive, { housingPeriodID: housingPeriodID });
        dispatch({ type: ENABLE_DISABLE_HOUSING_PERIOD_SUCCESS, payload: housingPeriodID });
        showEnableDisableSuccessToast(originalIsActive);
    }
    catch (err) {
        dispatch({ type: ENABLE_DISABLE_HOUSING_PERIOD_ERROR });
        console.error(err);
    }
}

export const loadHousingPeriodSelectItems = () => async (dispatch) => {
    dispatch({ type: LOAD_HOUSING_PERIOD_SELECT_ITEMS });

    try {
        const response = await getApi(housingAPIs.housingPeriods.selectItems);
        dispatch({ type: LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS, payload: response.data.data });
    }
    catch (err) {
        dispatch({ type: LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR });
        console.error(err);
    }
}

export const searchHousingPeriods = (searchCriteria) => async (dispatch) => {

    dispatch({ type: SEARCH_HOUSING_PERIODS });

    try {
        const response = await postApi(`${housingAPIs.housingPeriods.search}`, searchCriteria);
        const formattedResults = response.data.data.map(hp => ({
            ...hp,
            isActive: convertBooleanToYesNo(hp.isActive),
            startDate: convertISODateTimeStringToShortDate(hp.startDate),
            endDate: convertISODateTimeStringToShortDate(hp.endDate)
        }));

        dispatch({ type: SEARCH_HOUSING_PERIODS_SUCCESS, payload: formattedResults });
    }
    catch (err) {
        dispatch({ type: SEARCH_HOUSING_PERIODS_ERROR });
        console.error(err);
    }
}

export const loadHousingPeriod = (housingPeriodID) => async (dispatch) => {

    dispatch({ type: LOAD_HOUSING_PERIOD });

    // If the housing period ID passed in is null or undefined, we are adding a new housing period, in which case
    // we need to initialize the housing period. If it is defined, attempt to load it from the database.
    if (!housingPeriodID) {
        dispatch({ type: INITIALIZE_NEW_HOUSING_PERIOD })
    }
    else {
        try {
            const response = await getApi(`${housingAPIs.housingPeriods.getHousingPeriod}/${housingPeriodID}`);
            dispatch({ type: LOAD_HOUSING_PERIOD_SUCCESS, payload: response.data.data });
        }
        catch (err) {
            dispatch({ type: LOAD_HOUSING_PERIOD_ERROR });
            console.error(err);
        }
    }
}

export const saveHousingPeriodBasicDetails = (housingPeriodBasicDetails) => async (dispatch) => {

    const isEdit = housingPeriodBasicDetails?.housingPeriodID > 0;

    dispatch({ type: SAVE_HOUSING_PERIOD_BASIC_DETAILS });

    try {
        const response = await postApi(housingAPIs.housingPeriods.save, housingPeriodBasicDetails);
        dispatch({ type: SAVE_HOUSING_PERIOD_BASIC_DETAILS_SUCCESS, payload: response.data.data });
        isEdit ? showUpdateSuccessToast() : showAddSuccessToast();
    }
    catch (err) {
        dispatch({ type: SAVE_HOUSING_PERIOD_BASIC_DETAILS_ERROR });
        console.error(err);
    }
}

export const initializeHousingPeriodProperties = (request) => async (dispatch) => {

    dispatch({ type: INITIALIZE_HOUSING_PERIOD_PROPERTIES });

    try {
        const response = await postApi(housingAPIs.housingPeriods.properties.initializeHousingPeriodProperties, request);
        dispatch({ type: INITIALIZE_HOUSING_PERIOD_PROPERTIES_SUCCESS, payload: response.data.data });
        showAddSuccessToast();
    }
    catch (err) {
        dispatch({ type: INITIALIZE_HOUSING_PERIOD_PROPERTIES_ERROR });
        console.error(err);
    }
}

export const setManageHousingPeriodPropertiesSubstep = (substep) => async (dispatch) => {
    dispatch({ type: SET_MANAGE_HOUSING_PERIOD_PROPERTIES_SUBSTEP, payload: substep });
}

export const getPropertiesForHousingPeriod = (housingPeriodID) => async (dispatch) => {

    dispatch({ type: GET_PROPERTIES_FOR_HOUSING_PERIOD });

    try {
        const response = await getApi(`${housingAPIs.housingPeriods.properties.getPropertiesForHousingPeriod}/${housingPeriodID}`);
        const formattedResults = response.data.data.map(hpp => ({
            ...hpp,
            isActive: convertBooleanToYesNo(hpp.isActive),
        }));

        dispatch({ type: GET_PROPERTIES_FOR_HOUSING_PERIOD_SUCCESS, payload: formattedResults });
    }
    catch (err) {
        dispatch({ type: GET_PROPERTIES_FOR_HOUSING_PERIOD_ERROR });
        console.error(err);
    }
}

export const loadManageChargesSelectItems=()=>async(dispatch)=>{
    try {
        const response = await getApi(housingAPIs.housingPeriods.properties.getChargesDropdowns);
        dispatch({ type: LOAD_MANAGE_CHARGES_SELECT_ITEMS_SUCCESS, payload: response.data.data });      
    }
    catch (err) {
        dispatch({ type: LOAD_MANAGE_CHARGES_SELECT_ITEMS_ERROR });
        console.error(err);
    }
}


export const saveManageCharges = (housingPeriodPropertyManageCharges) => async (dispatch) => {

    dispatch({ type: SAVE_MANAGE_CHARGES });

    try {      
      const response = await postApi(
        housingAPIs.housingPeriods.properties.saveManageCharges,
        housingPeriodPropertyManageCharges
      );

      dispatch(
        loadManageCharges(
          MANAGE_CHARGES_SUBSTEP,
          response.data.data.housingPeriodPropertyId
        )
      );
      showAddSuccessToast();
    }
    catch (err) {
        console.error(err);
    }
}



export const loadHousingPeriodPropertyApplications = (housingPeriodProperties) => async (dispatch) => {    

    if(housingPeriodProperties.length === 1) {
        dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS, payload: housingPeriodProperties });
        try {
            const response = await getApi(`${housingAPIs.housingPeriods.housingPeriodPropertyApplications.searchHousingPeriodPropertyApplications}/${housingPeriodProperties[0].housingPeriodPropertyID}`);
            dispatch({
                type: LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_SUCCESS, payload: {
                    selectedHousingPeriodPropertyID: housingPeriodProperties[0].housingPeriodPropertyID,
                    housingPeriodPropertyApplications: response.data.data
                }
            });
        }
        catch (err) {
            dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_APPLICATIONS_ERROR });
            console.error(err);
        }
    } else {
        dispatch({ type: ADD_HOUSING_PERIOD_PROPERTIES, payload: housingPeriodProperties });
    }
}

export const loadHousingPeriodPropertyAddApplications = (properties) => async (dispatch) => {

    dispatch({ type: ADD_HOUSING_PERIOD_PROPERTIES, payload: properties });

}


export const addHousingPeriodPropertyApplication = (housingPeriodPropertyID) => async (dispatch) => {

    dispatch({ type: ADD_HOUSING_PERIOD_PROPERTY_APPLICATION });
    try {
        const response = await getApi(`${housingAPIs.housingPeriods.housingPeriodPropertyApplications.addHousingPeriodPropertyApplications}/${housingPeriodPropertyID}`);
        dispatch({ type: ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS, payload: response.data.data });
    } catch (err) {
        dispatch({ type: ADD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR });
        console.error(err);
    }
}

export const deleteHousingPeriodPropertyApplication = (housingPeriodPropertyApplicationID, housingPeriodProperty) => async (dispatch) => {
    dispatch({ type: DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION });
    try {
        await deleteApi(`${housingAPIs.housingPeriods.housingPeriodPropertyApplications.deleteHousingPeriodPropertyApplication}/${housingPeriodPropertyApplicationID}`);
        showDeleteSuccessToast();
        await dispatch(loadHousingPeriodPropertyApplications([housingPeriodProperty]));
    } catch (err) {
        dispatch({ type: DELETE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR });
        console.error(err);
    }

}

export const loadHousingPeriodPropertyApplication = (housingPeriodPropertyApplicationID, housingPeriodPropertyID) => async (dispatch) => {

    dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION });

    // If the ID passed in is null or undefined, we are adding a new entity, in which case
    // we need to initialize. If it is defined, attempt to load it from the database.
    if (!housingPeriodPropertyApplicationID) {
        dispatch({ type: INITIALIZE_NEW_HOUSING_PERIOD_PROPERTY_APPLICATION, payload: housingPeriodPropertyID })
    }
    else {
        try {
            const response = await getApi(`${housingAPIs.housingPeriods.housingPeriodPropertyApplications.getHousingPeriodPropertyApplication}/${housingPeriodPropertyApplicationID}`);
            dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_SUCCESS, payload: response.data.data });
        }
        catch (err) {
            dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR });
            console.error(err);
        }
    }
}

export const setSelectedProperties = (substep,selectedProperties) => async (dispatch) => {
    dispatch({ type: SET_SELECTED_HOUSING_PERIOD_PROPERTY, payload: {substep,selectedProperties} });
}

export const loadManageCharges = (substep, housingPeriodPropertyID) => async (dispatch) => {
    dispatch({ type: LOAD_MANAGE_CHARGES });
    try {
        const response = await getApi(`${housingAPIs.housingPeriods.properties.getCharges}/${housingPeriodPropertyID}`);  
        dispatch({
          type: LOAD_MANAGE_CHARGES_SUCCESS,
          payload: { substep, housingPeriodPropertyManageCharges: response.data.data },
        });
    }
    catch (err) {
        dispatch({ type: LOAD_MANAGE_CHARGES_ERROR });
        console.error(err);
    }
}



export const loadHousingPeriodPropertyMoveDates = (substep, housingPeriodPropertyID) => async (dispatch) => {
    dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY });

    try {
        const response = await getApi(`${housingAPIs.housingPeriods.properties.getMoveDates}/${housingPeriodPropertyID}`);
        dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS, payload: { substep, housingPeriodProperty: response.data.data } });
    }
    catch (err) {
        dispatch({ type: LOAD_HOUSING_PERIOD_PROPERTY_ERROR });
        console.error(err);
    }
}


export const saveHousingPeriodPropertyMoveDates =
  (housingPeriodProperty, isEdit=false) => async (dispatch) => {
    await dispatch({ type: SAVE_HOUSING_PERIOD_PROPERTY });
    try {
      const response = await postApi(
        housingAPIs.housingPeriods.properties.saveMoveDates,
        housingPeriodProperty
      );
      if(response?.data?.data?.length === 1) {
        await dispatch({type: SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS, payload: response.data.data,});
        isEdit ? showUpdateSuccessToast() : showAddSuccessToast();
      }else {
        await dispatch({type: SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS, payload: response.data.data});
        showAddSuccessToast();
         await dispatch({ type: CLEAR_HOUSING_PERIOD_PROPERTY });
        await dispatch(getPropertiesForHousingPeriod(selectHousingPeriod?.housingPeriodID));
      }
    } catch (err) {
      await dispatch({ type: SAVE_HOUSING_PERIOD_PROPERTY_ERROR });
      console.error(err);
    }
  };

export const clearHousingPeriodPropertyList = () => async (dispatch) => {
    await dispatch({ type: CLEAR_HOUSING_PERIOD_PROPERTY_LIST })
}


export const saveHousingPeriodPropertyApplication = (housingPeriodPropertyApplication) => async (dispatch) => {

    const isEdit = housingPeriodPropertyApplication?.housingPeriodPropertyApplicationID > 0;
    try {
        const response = await postApi(housingAPIs.housingPeriods.housingPeriodPropertyApplications.saveHousingPeriodPropertyApplication, housingPeriodPropertyApplication);
        if(response?.data?.data?.length === 1) { 
            isEdit ? showUpdateSuccessToast() : showAddSuccessToast();
            await dispatch(loadHousingPeriodPropertyApplication(response?.data?.data[0].housingPeriodPropertyApplicationID));
        } else {                
            await dispatch({ type: CLEAR_HOUSING_PERIOD_PROPERTY });
        }
    }
    catch (err) {
        dispatch({ type: SAVE_HOUSING_PERIOD_PROPERTY_APPLICATION_ERROR });
        console.error(err);
    }
}

export const clearHousingPeriodPropertyApplication = (housingPeriodProperties) => async (dispatch) => {
    await dispatch({ type: CLEAR_HOUSING_PERIOD_PROPERTY_APPLICATION });
    await dispatch(loadHousingPeriodPropertyApplications(housingPeriodProperties));
}

export const loadHousingPeriodRoomAssignmentPublishDates = (housingPeriodID) => async (dispatch) => {

    dispatch({ type: LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE });

    // If the ID passed in is null or undefined, we are adding a new entity, in which case
    // we need to initialize. If it is defined, attempt to load it from the database.
    if (!housingPeriodID) {
        dispatch({ type: INITIALIZE_NEW_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE, payload: housingPeriodID })
    } else {
        try {
            const response = await getApi(housingAPIs.housingPeriods.roomAssignmentPublishDates.getRoomAssignmentPublishDates.replace('{housingPeriodID}', housingPeriodID));
            dispatch({ type: LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS, payload: response.data.data });
        } catch (err) {
            dispatch({ type: LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR });
            console.error(err);
        }
    }
}



export const loadHousingPeriodApplicationSubmissions = (housingPeriodUUID) => async (dispatch) => {
    dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS, payload: housingPeriodUUID });
};

//Actions for applications for processing
export const searchHousingPeriodApplicationSubmissions = ( searchCriteria) => async (dispatch)=>{
    dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS } );
    try {
        const response = await postApi(`${housingAPIs.housingPeriods.properties.search}`, searchCriteria);
        dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_SUCCESS, payload: response.data.data });
    }
    catch (err) {
        dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATION_SUBMISSIONS_ERROR });
        console.error(err);
    }

};

export const loadHousingPeriodApplicationSelectItems = () => async (dispatch) => {
    dispatch({ type: LOAD_HOUSING_PERIOD_SELECT_ITEMS });

    try {
        const response = await getApi(housingAPIs.housingPeriods.selectItems);
        dispatch({ type: LOAD_HOUSING_PERIOD_SELECT_ITEMS_SUCCESS, payload: response.data.data });
    }
    catch (err) {
        dispatch({ type: LOAD_HOUSING_PERIOD_SELECT_ITEMS_ERROR });
        console.error(err);
    }
}

export const searchHousingPeriodApplications = (housingPeriodId) => async (dispatch)=>{
    dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATIONS });
    
    try {
        const response = await getApi(`${housingAPIs.housingPeriods.applications}/${housingPeriodId}/applications`);

        dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATIONS_SUCCESS, payload: response.data.data });
    }
    catch (err) {
        dispatch({ type: SEARCH_HOUSING_PERIOD_APPLICATIONS_ERROR });
        console.error(err);
    }
};

export const loadHousingPeriodApplications = (housingPeriodId) => async (dispatch)=>{
    dispatch({ type: LOAD_HOUSING_PERIOD_APPLICATIONS });

    try {
        const response = await getApi(`${housingAPIs.housingPeriods.applications}/${housingPeriodId}/applications`);

        dispatch({ type: LOAD_HOUSING_PERIOD_APPLICATIONS_SUCCESS, payload: response.data.data });
    }
    catch (err) {
        dispatch({ type: LOAD_HOUSING_PERIOD_APPLICATIONS_ERROR });
        console.error(err);
    }
};

export const loadHousingPeriodRoomAssignments = (housingPeriodID) => async (dispatch)=>{
    dispatch({ type: LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS });
};

export const searchHousingPeriodRoomAssignments = (searchParam) => async (dispatch)=>{
   
    try {
        const response = await postApi(`${housingAPIs.housingPeriods.housingPeriodRoomAssignments.search}`, searchParam);

        dispatch({ type: LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_SUCCESS, payload: response.data.data });
    }
    catch (err) {
        dispatch({ type: LOAD_HOUSING_PERIOD_ROOM_ASSIGNMENTS_ERROR });
        console.error(err);
    }
};


export const loadHousingProperties = () => async (dispatch) => {

    dispatch({ type: LOAD_HOUSING_PROPERTIES });

    try {
        const response = await getApi(`${housingAPIs.property.search}`);
        dispatch({ type: LOAD_HOUSING_PROPERTIES_SUCCESS, payload: [...response.data.data] });
    }
    catch (err) {
        dispatch({ type: LOAD_HOUSING_PROPERTIES_ERROR });
        console.error(err);
    }
}

export const loadHousingRoomAssignmentPulishDates = (housingPeriodID, roomAssignmentPublishDateID) => async (dispatch) => {
    dispatch({ type: LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE });

    // If the ID passed in is null or undefined, we are adding a new entity, in which case
    // we need to initialize. If it is defined, attempt to load it from the database.
    if (!housingPeriodID || !roomAssignmentPublishDateID) {
        dispatch({ type: INITIALIZE_ROOM_ASSIGNMENT_PUBLISH_DATE, payload: roomAssignmentPublishDateID });
    } else {
        try {
            const response = await getApi(`${housingAPIs.housingPeriods.roomAssignmentPublishDates.getRoomAssignmentPublishDate.replace('{housingPeriodID}', housingPeriodID)}/${roomAssignmentPublishDateID}`);
            dispatch({ type: LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_SUCCESS, payload: response.data.data });
        } catch (err) {
            dispatch({ type: LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR });
            console.error(err);
        }
    }
}

export const setManageHousingPeriodRoomAssignmentPublishDate = (substep) => async (dispatch) => {
    dispatch({ type: SET_MANAGE_ROOM_ASSIGNMENT_PUBLISH_DATE, payload: substep });
}

export const setEditHousingRoomsAssignmentPublishDate = (payload) => async (dispatch) => {
    dispatch({ type: EDIT_ROOM_ASSIGNMENT_PUBLISH_DATE, payload: payload });
}

export const setDeleteHousingAssignmentPublishDate = (housingPeriodID, roomAssignmentPublishDateID, payload) => async (dispatch) => {
    if(!roomAssignmentPublishDateID){
        dispatch(setDeleteHousingAssignmentPublishDateInMemory(payload));
    }
    try {
        await deleteApi(`${housingAPIs.housingPeriods.roomAssignmentPublishDates.deleteRoomAssignmentPublishDate.replace('{housingPeriodID}', housingPeriodID)}/${roomAssignmentPublishDateID}`);
        dispatch({ type: DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY, payload: payload });
    } catch (err) {
        dispatch({ type: LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR });
        console.error(err);
    }
}

export const saveEditHousingRoomsAssignmentPublishDate = (housingPeriodID, isEdit, payload) => async (dispatch) => {
    try {
        const response = await postApi(`${housingAPIs.housingPeriods.roomAssignmentPublishDates.saveRoomAssignmentPublishDate.replace('{housingPeriodID}', housingPeriodID)}`, payload);
        dispatch({ type: SET_ROOM_ASSIGNMENT_PUBLISH_DATE, payload: response.data.data });
        isEdit ? showUpdateSuccessToast() : showAddSuccessToast();
    } catch (err) {
        dispatch({ type: LOAD_ROOM_ASSIGNMENT_PUBLISH_DATE_ERROR });
        console.error(err);
    }
}

export const setHousingPeriodBreadcrumb = (payload) => async (dispatch) => {
    dispatch({ type: SET_HOUSING_PERIOD_BREADCRUMB, payload: payload });
}

export const setDeleteHousingAssignmentPublishDateInMemory = (payload) => async (dispatch) => {
    dispatch({ type: DELETE_ROOM_ASSIGNMENT_PUBLISH_DATE_IN_MEMORY, payload: payload });
}