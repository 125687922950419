import React from 'react';
import {
    DataGridPro,
    GridEventListener,
    GridToolbarContainer,
    useGridApiContext,
    gridVisibleSortedRowIdsSelector,
    GridCsvExportOptions,
    GridCsvGetRowsToExportParams,
    GridToolbarColumnsButton,
    GridToolbarFilterButton,
    GridColumnMenuContainer,
    GridFilterMenuItem,
    SortGridMenuItems,
    HideGridColMenuItem,
    GridColumnsMenuItem,
    GridSelectedRowCount
} from '@mui/x-data-grid-pro';
import setLicenseKey from './DataGridLicense'
import { ReactComponent as GridColumnsIcon } from '../../assests/icons/Grid_column.svg'
import { ReactComponent as GridFilterIcon } from '../../assests/icons/Grid_filter.svg'
import { ReactComponent as GridExportIcon } from '../../assests/icons/Grid_export.svg'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ContentPasteOutlinedIcon from '@mui/icons-material/ContentPasteOutlined';
import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import ShareOutlinedIcon from '@mui/icons-material/ShareOutlined';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Checkbox } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import CustomLoader from '../../components/Loader/Loader';
import GenericModal from '../../components/Modal/GenericModal'
import BrandingColors from '../../styles/color';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import { Add} from '@material-ui/icons';

const getFilteredRows = ({ apiRef }: GridCsvGetRowsToExportParams) =>
    gridVisibleSortedRowIdsSelector(apiRef);

const ElementsDataGridToolbar = (props) => {
    const { selectionModel, selectionModelIds, onEdit, onDelete, deleteDisable, onEnable, onView, onCopy, onShare, initialSorting, trackingLabel, selectionModelRow,
        addEditAccess, shareAccess, viewAccess, onAdd, isDelete = true, hideFooterSelectedRowCount,showDownload = true, onMoreActions, moreActionsAccess, onWorkflowAction  } = props;
    const apiRef = useGridApiContext();
    
    const [confirmOpen, setConfirmOpen] = React.useState(false);
    const [shareChecked, setShareChecked] = React.useState(false);

    const handleExport = (options: GridCsvExportOptions) =>
        apiRef.current.exportDataAsCsv(options);

    const buttonBaseProps: ButtonProps = {
        color: 'primary',
        size: 'small'
    };

    const disabledIcon = !selectionModel || selectionModel.length !== 1
            
    return (
        <GridToolbarContainer>
            {hideFooterSelectedRowCount && selectionModel?.length >= 1 &&
                   <GridSelectedRowCount  className="RowSelectedCount"  selectedRowCount={selectionModel?.length} />
            }
            {showDownload && <Tooltip title="Download" arrow><Button
                {...buttonBaseProps}
                startIcon={<GridExportIcon />}
                onClick={() => {
                    handleExport({ getRowsToExport: getFilteredRows, fileName: trackingLabel })
                }} >
            </Button></Tooltip> 
            }
            <Tooltip title="Show or hide columns" arrow><GridToolbarColumnsButton aria-label='Show/Hide Columns' startIcon={<GridColumnsIcon />} /></Tooltip>
            <GridToolbarFilterButton
                componentsProps={{
                    button: {
                        startIcon: (
                            <GridFilterIcon />
                        )
                    }
                }}
            />
            {onCopy && addEditAccess &&
                <Tooltip title="Copy" arrow>
                    <span><Button
                        {...buttonBaseProps}
                        startIcon={<ContentCopyOutlinedIcon color={disabledIcon ? "disabled" : ""}
                        style={{ pointerEvents: disabledIcon ? 'none' : "" }} />}
                        sx={{
                            '&.Mui-disabled': {
                                background: 'none !important'
                            }
                        }}
                        disabled={disabledIcon}
                        onClick={(e) => { onCopy(selectionModelRow[0]); }}
                    ></Button></span></Tooltip>}
            {onEnable && addEditAccess &&
                <Tooltip title={selectionModel?.length >= 1 ? (selectionModelRow[0]?.isActive === true || selectionModelRow[0]?.isActive === 'Yes') ? "Disable" : "Enable" : null} arrow>
                    <span><Button
                        {...buttonBaseProps}
                        startIcon={selectionModel?.length >= 1 ? (selectionModelRow[0]?.isActive === true || selectionModelRow[0]?.isActive === 'Yes') ? <ContentPasteOffOutlinedIcon /> : <ContentPasteOutlinedIcon/> 
                        : <ContentPasteOffOutlinedIcon color={disabledIcon ? "disabled" : ""}
                        style={{ pointerEvents: disabledIcon ? 'none' : "" }} />}
                        sx={{
                            '&.Mui-disabled': {
                                background: 'none !important'
                            }
                        }}
                        disabled={disabledIcon}
                        onClick={(e) => { onEnable(selectionModelRow[0]); }}
                    ></Button></span></Tooltip>}
            {onDelete && addEditAccess &&
                <Tooltip title="Delete" arrow><span><Button
                    {...buttonBaseProps}
                    className={deleteDisable ? 'disableDeleteButton' : ''}
                    startIcon={<DeleteOutlineOutlinedIcon color={disabledIcon ? "disabled" : ""}
                    style={{ pointerEvents: disabledIcon ? 'none' : "" }} />}
                    sx={{
                        '&.Mui-disabled': {
                            background: 'none !important'
                        }
                    }}
                    disabled={disabledIcon}
                    onClick={() => {
                        setConfirmOpen(true);
                        /* Funtion not Implemented */
                        // setConfirmOpen(isDelete);
                        // onDelete(selectionModelRow[0]);
                    }}>
                </Button></span></Tooltip>}
            {onEdit && addEditAccess &&
                <Tooltip title="Edit" arrow><span><Button
                    {...buttonBaseProps}
                    startIcon={<EditOutlinedIcon color={disabledIcon ? "disabled" : ""}
                    style={{ pointerEvents: disabledIcon ? 'none' : "" }} />}
                    sx={{
                        '&.Mui-disabled': {
                            background: 'none !important'
                        }
                    }}
                    disabled={disabledIcon}
                    onClick={(e) => { onEdit(selectionModelRow[0]); }} >
                </Button></span></Tooltip>}
            {onAdd && addEditAccess && <Tooltip title="Add" arrow><Button
                {...buttonBaseProps}
                startIcon={<Add />}
                onClick={(e) => { onAdd(); }} >
            </Button></Tooltip>}
            {onView && viewAccess &&
                <Tooltip title="View" arrow>
                    <span><Button
                        {...buttonBaseProps}
                        startIcon={<RemoveRedEyeOutlinedIcon color={disabledIcon ? "disabled" : ""}
                        style={{ pointerEvents: disabledIcon ? 'none' : "" }} />}
                        sx={{
                            '&.Mui-disabled': {
                                background: 'none !important'
                            }
                        }}
                        disabled={disabledIcon}
                        onClick={(e) => { onView(selectionModelRow[0]); }}
                    ></Button></span></Tooltip>}
            {onMoreActions && moreActionsAccess &&
                <Tooltip title="More Actions" arrow>
                    <Button
                        {...buttonBaseProps}
                        startIcon={<MoreVertIcon />}
                        onClick={onMoreActions}
                    >
                    </Button>
                </Tooltip>
            }
            {selectionModel?.length >= 1 && onShare && shareAccess &&
                <>
                    <Tooltip title="Share" arrow><Button
                        {...buttonBaseProps}
                        startIcon={<ShareOutlinedIcon />}
                        onClick={(e) => {
                            // onShare(selectionModelRow[0]); 
                            setShareChecked((prev) => !prev);
                        }}
                    ></Button></Tooltip>
                    {shareChecked &&
                        <Paper style={{
                            position: 'absolute',
                            zIndex: '1',
                            top: '50px',
                            right: '140px'
                        }}>
                            <List>
                                <ListItem>
                                    Copy Link
                                </ListItem>
                                <ListItem>
                                    Send Email
                                </ListItem>
                            </List>
                        </Paper>
                    }
                </>
            }

            <GenericModal
                open={confirmOpen}
                title={"Are you sure you want to delete? "}
                buttonText={"Yes"}
                button2Text={"No"}
                onButtonPress={() => {
                    onDelete(selectionModelRow);
                }}
                onClose={setConfirmOpen}
            />
        </GridToolbarContainer>
    );
};

const ElementsColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
        <GridColumnMenuContainer
            hideMenu={hideMenu}
            currentColumn={currentColumn}
        >
            <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
            <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
            <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
            <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
        </GridColumnMenuContainer>
    )
}

export default function ElementsDataGridPro(props) {

    const { isLoading,
        multiSelect,
        headCells,
        initialSorting,        
        rows, 
        actionCols,
        pagination,
        onDelete,
        deleteDisable,
        onEdit,
        onEnable,
        onView,
        onCopy,
        onShare,
        isLookup,
        onRowSelect, 
        actionFlag, 
        trackingLabel, 
        dataGridToolbar, 
        dataGridColumnMenus,
        hideFooterSelectedRowCount = false,
        addEditAccess, 
        shareAccess, 
        viewAccess, 
        onAdd, 
        isDelete,
        showDownload = true,
        onMoreActions,
        moreActionsAccess, 
        defaultColumns = {},
        onWorkflowAction,
        selectionModelChanged,
        emptyRowsMessage = 'No Data Available',
        emptyResultsMessage  = 'No Data Available',
        autoHeight = true
    } = props;

    setLicenseKey()

    const [page, setPage] = React.useState(0)
    const [pageSize, setPageSize] = React.useState(20)
    const maxVisibleRows = 8

    const dgColumns = [
        ...(!isLookup && !multiSelect
            ? [{
                field: "",
                headerName: "",
                disableColumnMenu: true,
                disableExport: true,
                sortable: false,
                resizable: false,
                hideable: false,
                filterable: false,
                width: 50,
                renderCell: (params) => (
                    <Checkbox
                        checked={rowChecked != null && params != null && rowChecked[0] === params.id}
                        value={params != null ? params.id : null}
                    />)
            }]
            : []
        ),
        ...headCells.map(headCell => ({
            field: headCell.id,
            sortable: headCell.sortable??true,
            editable: headCell.editable??false,
            filterable: headCell.filterable??true,
            headerName: headCell.label,
            disableColumnMenu: !dataGridColumnMenus,
            valueFormatter: headCell.valueFormatter,
            renderCell:headCell.renderCell,
            flex: 1,
            type: headCell.type,
            align: headCell.align,
            headerAlign: headCell.headerAlign,
        }))
    ]

    actionCols?.map((col,index) => (
        dgColumns.push({
        id:index,
        ...col 
    })))

    const dgRows = rows.map((row, index) => ({
        id: index,
        ...row
    }))

    const displayToolbar = dataGridToolbar ? "block" : "none"

    let rowChecked;

    const handleRowClick: GridEventListener<'rowClick'> = (params) => {
        (onRowSelect) && onRowSelect(params.row)
    }

    const handleRowDoubleClick: GridEventListener<'rowDoubleClick'> = (params) => {
     
    
        if (!actionFlag) {
            if (onEdit && addEditAccess){
                onEdit(params.row);
                
            }
            if(onView && viewAccess){
                onView(params.row);
            }
          
        }
    }

    const [selectionModel, setSelectionModel] = React.useState(rowChecked);
    const [selectionModelIds, setSelectionModelIds] = React.useState(rowChecked);
    const [deletedModelIds, setDeletedModelIds] = React.useState([]);
    rowChecked = selectionModel;
    //New Changes starts- Row Selection
    const [selectionModelRow, setSelectionModelRow] = React.useState([]);
    //New Changes Ends- Row Selection
    const [columnVisibilityModel, setColumnVisibilityModel] = React.useState(defaultColumns);

    return (
        <>
            
            <DataGridPro
                autoHeight={autoHeight}
                loading={isLoading}
                role='grid'
                components={{
                    Toolbar: ElementsDataGridToolbar,
                    ColumnMenu: ElementsColumnMenu,
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            {emptyRowsMessage}
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            {emptyResultsMessage}
                        </Stack>
                    )
                }}
                componentsProps={{
                    toolbar: {
                        selectionModel,
                        selectionModelIds,
                        onEdit,
                        onDelete,
                        deleteDisable,
                        onEnable,
                        onView,
                        onCopy,
                        onShare,
                        rows,
                        trackingLabel,
                        selectionModelRow,                        
                        addEditAccess, shareAccess, viewAccess, onAdd, isDelete, showDownload,
                        onMoreActions,
                        moreActionsAccess,
                        onWorkflowAction ,
                        hideFooterSelectedRowCount,
                        selectionModelChanged
                    },
                    basePopper: {
                        sx: {
                            '&.MuiDataGrid-menu .MuiPaper-root': {
                                width: '188px',
                                backgroundColor: '~FFFFFF',
                                border: '1px solid rgba(210, 210, 210, 0.58)',
                                boxShadow: '0px 0px 6px rgba(211, 211, 211, 0.74)',
                                borderRadius: '3px',
                                '& .MuiDataGrid-menuList': {
                                    '& .MuiMenuItem-root': {
                                        display: 'block-flex',
                                        paddingLeft: '16px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#646569'
                                    },
                                    '& .MuiMenuItem-root:hover': {
                                        color: '#000000',
                                        backgroundColor: '#FBF4E5'
                                    }
                                }
                            },
                            '&.MuiDataGrid-panel .MuiPaper-root': {
                                '& .MuiDataGrid-panelHeader': {
                                    padding: '16px 16px 0px 16px',
                                    '& .MuiInputLabel-standard': {
                                        color: '#646569',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    },
                                    '& .MuiInput-input': {
                                        color: '#000000',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    }
                                },
                                '& .MuiDataGrid-panelContent': {
                                    padding: '16px 16px 16px 16px',
                                    '& .MuiSwitch-sizeSmall': {
                                        width: '60px'
                                    },
                                    '& .MuiSwitch-switchBase': {
                                        color: '#96989A'
                                    },
                                    '& .MuiSwitch-switchBase+.MuiSwitch-track': {
                                        backgroundColor: '#E2E2E2',
                                        opacity: '1',
                                        transform: 'translateX(-16px)'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked': {
                                        color: '#0A66C2'
                                    },
                                    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
                                        backgroundColor: '#E3ECF5',
                                        opacity: '1'
                                    },
                                    '& .MuiFormControlLabel-label': {
                                        color: '#646569',
                                        fontSize: '14px',
                                        fontWeight: '400'
                                    },
                                    '& .MuiDataGrid-filterForm': {
                                        '& .MuiIconButton-sizeSmall:hover': {
                                            backgroundColor: '#FBF4E5'
                                        },
                                        '& .MuiDataGrid-filterFormColumnInput, .MuiDataGrid-filterFormOperatorInput': {
                                            marginRight: '16px'
                                        },
                                        '& .MuiInputLabel-standard': {
                                            fontSize: '16px',
                                            fontWeight: '700',
                                            color: '#646569'
                                        },
                                        '& .MuiInput-input': {
                                            fontSize: '14px',
                                            fontWeight: '400',
                                            color: '#646569'
                                        },
                                        '& .MuiNativeSelect-select': {
                                            paddingLeft: '2px',
                                            paddingRight: '24px',
                                            minWidth: '16px'
                                        }
                                    },
                                    
                                },
                                '& .MuiDataGrid-panelFooter': {
                                    padding: '0px 16px 16px 16px',
                                    '& .MuiButton-root': {
                                        backgroundColor: '#E3ECF5',
                                        color: '#0A66C2',
                                        border: '1px solid #E3ECF5',
                                        borderRadius: '4px',
                                        padding: '0px 10px 0px 10px',
                                        minHeight: '28px',
                                        fontWeight: '600',
                                        fontSize: '12px',
                                        textTransform: 'capitalize'
                                    },
                                }
                            }
                        }
                    },
                    pagination: {
                        SelectProps: {
                            MenuProps: {
                                sx: {
                                    '.MuiList-padding': {
                                        padding: '1px 1px 1px 1px'
                                    },
                                    '.MuiTablePagination-menuItem': {
                                        display: 'block-flex',
                                        paddingLeft: '8px',
                                        fontSize: '14px',
                                        fontWeight: '400',
                                        color: '#646569'
                                    },
                                    '.MuiTablePagination-menuItem:hover, .MuiTablePagination-menuItem.Mui-selected:hover': {
                                        color: '#000000',
                                        backgroundColor: '#E6F5F9',
                                        border: '1px solid #0A66C2',
                                    },
                                    '.MuiTablePagination-menuItem.Mui-selected': {
                                        backgroundColor: '#FFFFFF'
                                    }
                                }
                            }
                        }
                    }
                }}
                localeText={{
                    toolbarColumns: "",
                    toolbarFilters: ""
                }}
                onRowClick={handleRowClick}
                onRowDoubleClick={handleRowDoubleClick}
                
                disableMultipleSelection={!multiSelect}
                checkboxSelection={multiSelect}

                columns={dgColumns}
                rows={dgRows}
                initialState={initialSorting}

                page={page}
                onPageChange={(newPage) => setPage(newPage)}

                pagination={pagination}
                hideFooterRowCount={!pagination}
                hideFooterSelectedRowCount={hideFooterSelectedRowCount}
                rowsPerPageOptions={[20, 50, 100]}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                selectionModel={selectionModel}
                onSelectionModelChange={(newSelectionModel) => {
                    setSelectionModel(newSelectionModel);
                    const selectedIndexes = new Set(newSelectionModel)
                    const selectedRows = dgRows.filter((row) =>
                        selectedIndexes.has(row.id)
                    )
                    let ids = []
                    selectedRows.forEach((element) => {
                        ids.push(Object.values(element)[1])
                    })
                    setSelectionModelIds(ids);
                    //New Changes starts- Row Selection
                    setSelectionModelRow(selectedRows)
                    if(selectionModelChanged)
                        selectionModelChanged(selectedRows);
                    //New Changes ends- Row Selection
                }}
                columnVisibilityModel={columnVisibilityModel}
                onColumnVisibilityModelChange={(newModel) =>
                    setColumnVisibilityModel(newModel)
                }
                sx={{
                    width: '100%',
                    height: 160 + Math.min(maxVisibleRows, Math.min(pageSize, dgRows.length + 1)) * 52,
                    minHeight: 250,
                    background: '#FFFFFF',
                    border: '1px solid rgba(210, 210, 210, 0.58)',
                    boxShadow: '0px 0px 6px rgba(211, 211, 211, 0.74)',
                    borderRadius: '3px',
                    '.MuiDataGrid-toolbarContainer': {
                        justifyContent: 'flex-end',
                        padding: '5px 20px 2px 20px',
                        borderBottom: '1px solid rgba(224, 224, 224, 1)',
                        '& .MuiButton-root': {
                            minWidth: '38px',
                            minHeight: '38px',
                            '& .MuiButton-startIcon': {
                                color: '#646569'
                            },
                            '& .MuiButton-iconSizeSmall': {
                                marginLeft: '-2px',
                                marginRight: '-2px'
                            }
                        },
                        '& .MuiButton-root:hover': {
                            backgroundColor: "#FBF4E5",
                            borderRadius: "50px",
                            height: "38px",
                            width: "38px",
                            '& svg': {
                                filter: 'brightness(0) saturate(100%)'
                            }
                        },
                        '& .MuiButtonBase-root.Mui-disabled': {
                            backgroundColor: 'transparent !important',
                            color: "#646464 !important"
                        },
                        '& .RowSelectedCount': {
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#646569',
                            marginRight: 'auto',
                            paddingTop: '7px',
                            paddingLeft: '14px'
                        },
                        '& .disableDeleteButton': {
                            pointerEvents: 'none',
                        },
                    },
                    '.MuiDataGrid-columnHeaders': {
                        padding: '0px 16px 0px 16px',
                        borderBottomColor: `${BrandingColors.brightOrange}`,
                        '& .MuiDataGrid-columnHeader': {
                            backgroundColor: '#FFFFFF'
                        }
                    },
                    '.MuiDataGrid-iconButtonContainer, .MuiDataGrid-menuIcon': {
                        '.MuiIconButton-root': {
                            height: '24px',
                            width: '24px',
                        },
                        '.MuiIconButton-root:hover': {
                            backgroundColor: '#FBF4E5',
                            '& svg': {
                                filter: 'brightness(0) saturate(100%)'
                            }
                        }
                    },
                    '.MuiDataGrid-main': {
                        padding: '0px 16px 0px 16px'
                    },
                    '.MuiDataGrid-columnHeader': {
                        boxSizing: 'border-box'
                    },
                    '.MuiDataGrid-columnHeaderTitleContainer': {
                        justifyContent: 'flex-start'
                    },
                    '.MuiDataGrid-columnHeaderTitle': {
                        color: '#646569',
                        fontSize: '14px',
                        fontWeight: '700'
                    },
                    '.MuiDataGrid-cellContent': {
                        color: '#646569',
                        fontSize: '14px',
                        fontWeight: '400'
                    },
                    '.MuiDataGrid-row.Mui-selected': {
                        backgroundColor: `${BrandingColors.hoverOrangeCustom}`,
                        '& .MuiDataGrid-cell': {
                            '& .MuiDataGrid-cellContent': {
                                color: '#000000'
                            }
                        }
                    },
                    '.MuiDataGrid-rowCount': {
                        float: 'right'
                    },
                    '.MuiDataGrid-row:hover, .MuiDataGrid-row.Mui-selected:hover': {
                        backgroundColor: '#E6F5F9'
                    },
                    '[data-testid="CheckBoxOutlineBlankIcon"]': {
                        color: '#646569'
                    },
                    '.MuiMenu-paper .MuiMenu-list': {
                        fontWeight: '700',
                        display: 'inline-block'
                    },
                    '.MuiDataGrid-footerContainer': {
                        padding: '10px 16px 16px 16px',
                        '& .MuiDataGrid-selectedRowCount, & .MuiTablePagination-selectLabel, & .MuiTablePagination-displayedRows, & .MuiTablePagination-select': {
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#646569'
                        },
                        '& .MuiTablePagination-select:hover, .MuiTablePagination-select:focus': {
                            backgroundColor: '#E6F5F9'
                        },
                        '& .MuiIconButton-sizeMedium': {
                            height: '34px',
                            width: '34px'
                        },
                        '& .MuiIconButton-sizeMedium:hover': {
                            backgroundColor: '#E6F5F9'
                        },
                        '& .MuiButtonBase-root.Mui-disabled': {
                            cursor: 'default',
                            pointerEvents: 'none',
                            backgroundColor: 'transparent !important',
                            opacity: '0.38'
                        }
                    },
                    '.MuiTablePagination-menuItem': {
                        display: 'block-flex'
                    }
                }}
            />
        </>
    )
}