export const selectSearchHousingPeriodsResults = (reducers) => reducers.housingPeriods.search.results;
export const selectHousingPeriod = (reducers) => reducers.housingPeriods.addEditWizard.housingPeriod;
export const selectedHousingPeriodProperties = (reducers) => reducers.housingPeriods.addEditWizard.selectedHousingPeriodProperties;
export const selectHousingPeriodApplications = (reducers) => reducers.housingPeriods.housingPeriodApplications;
export const selectHousingPeriodPermissions = (state) => {
    const housingPeriodPermissions = state.access.access.subNestedParentLevel
        .filter((v) => v.menuItemName.trim() === "Student Life-Housing Periods-Manage" && v.moduleorder === 12);

    return housingPeriodPermissions.length > 0
        ? housingPeriodPermissions[0].menuAccess
        : 0;
}
export const selectHousingPeriodRoomAssignmentPublishDates = (reducers) => reducers.housingPeriods.addEditWizard.housingPeriod.roomAssignmentPublishDates;
export const selectProcessAppliationsPermissions = (state) => {
    const processApplicationPermissions = state.access.access.subNestedParentLevel
        .filter((v) => v.menuItemName.trim() === "Student Life-Process Applications-Manage" && v.moduleorder === 12);

    return processApplicationPermissions.length > 0
        ? processApplicationPermissions[0].menuAccess
        : 0;
};
export const selectRoomAssignmentPermissions = (state) => {
     const selectRoomAssignmentPermissions = state.access.access.subNestedParentLevel
        .filter((v) => v.menuItemName.trim() === "Student Life-Assign Rooms-Manage" && v.moduleorder === 12);
    return selectRoomAssignmentPermissions.length > 0
            ? selectRoomAssignmentPermissions[0].menuAccess
            : 0;
};
export const selectHousingPeriodBreadcrumb = (state) => state.housingPeriods.breadcrumb;

