import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Card, CardContent, Typography } from '@material-ui/core';
import { useFormik } from 'formik';
import * as yup from 'yup'
import { TextInput, CheckBoxInput, MultipleSelectInputWithSelectAll } from '../../components/Forms';
import { FormGroup, useStyles, ButtonWrapperFilter } from '../../styles/common-styles'
import { useDispatch } from 'react-redux';
import { updateFormStatus } from '../../redux/common/formValueChange/action';

const CopyFloor = (props) => {
    const { t } = useTranslation(["studentLife"]);
    const classes = useStyles();
    const { onClose, selectedRow, handleCopyChanges, popupHeaderTitle, selectedName, floorDetails } = props;
    const dispatch = useDispatch();
    const [floorValuesChecked, setFloorValuesChecked]=useState(false);
    const [dropDownValuesCheck, setDropDownValuesCheck]=useState(false);
    const [formValues, setFormValues] = useState({
        copyFromFloors: selectedName,
        copyToFloors: "",
        selectall: "",
        totalUnitsOnFloor: "",
        gender: "",
        program: "",
        transfers: "",
        amenities: "",
        yearClassification: "",
        gpa: "",
        age: "",
        athletics: "",
        wheelchairAccess:""
    });
    
    const validationSchema = yup.object({
        copyToFloors: yup.array()
            .min(1, t('validationMessages.isRequired', { what: t('propertiesLabel.copyToFloors') }))
            .required(t('validationMessages.isRequired', { what: t('propertiesLabel.copyToFloors') })),
    });

    const formik = useFormik({
        initialValues: formValues,
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleCopyChanges(values);
            onClose();
        }
    });

    const handleSelectAll=(e)=>{
        const checked = e.target.checked;
        formik.setFieldValue("selectall", checked);
        formik.setFieldValue("totalUnitsOnFloor", checked);
        formik.setFieldValue("gender", checked);
        formik.setFieldValue("program", checked);
        formik.setFieldValue("transfers", checked);
        formik.setFieldValue("amenities", checked);
        formik.setFieldValue("yearClassification", checked);
        formik.setFieldValue("gpa", checked);
        formik.setFieldValue("age", checked);
        formik.setFieldValue("athletics", checked);
        formik.setFieldValue("wheelchairAccess", checked);
    }
    
    const handleChange=(e)=>{
        formik.setFieldValue(e.target.name, e.target.checked);
        if(!e.target.checked)formik.setFieldValue("selectall", false);
    }

    const hasAtLeastOneTrue = (obj) => {
        return Object.values(obj).some(value => value === true);
    }

    useEffect(()=>{
        setFloorValuesChecked(hasAtLeastOneTrue(formik.values));
        setDropDownValuesCheck(formik?.values?.copyToFloors.length > 0);
    },[formik?.values])
   
    return (
        <>
            <form onSubmit={formik.handleSubmit} autoComplete="off" className={classes.root}>
                <Card>
                    <CardContent>
                        <Typography component="h4" variant="h6" style={{ marginBottom: "30px", fontSize: "smaller" }}>{t('commonLabel.copyInfo')}</Typography>
                        <FormGroup>
                            <TextInput
                                labelId="copyFromFloors"
                                label={t('propertiesLabel.copyFromFloors')}
                                formikValue={formik.values.copyFromFloors}
                                formik={formik}
                                errors={formik.errors.copyFromFloors}
                                touched={formik.touched.copyFromFloors}
                                disabled={true}
                                formikChange={formik.handleChange}
                                onTooltip={t('propertiesLabel.copyFromFloors')}
                            />
                            <MultipleSelectInputWithSelectAll
                                customStyle={{
                                    width: "50%",
                                    marginRight: "0px"
                                }}
                                mandatory
                                label={t('propertiesLabel.copyToFloors')}
                                labelId={'copyToFloors'}
                                selectAllText={t('propertiesLabel.selectallfloors')}
                                formik={formik}
                                errors={formik.errors.copyToFloors}
                                touched={formik.touched.copyToFloors}
                                list={selectedRow}
                                onTooltip={t('propertiesLabel.copyToFloors')}                                
                            />
                        </FormGroup>
                    </CardContent>
                    <hr></hr>
                    <CardContent>
                        <Typography component="h3" variant="h5" style={{ marginBottom: "10px", fontSize: "larger" }}>{t('commonLabel.floorDetails')}</Typography>
                        <CheckBoxInput
                                label={t(popupHeaderTitle)}
                                labelId='selectall'
                                formikValue={formik.values.selectall}
                                formikChange={handleSelectAll}
                                isDoubled
                                checked={formik.values.selectall}
                            />
                        <FormGroup style={{padding:"20px"}}>
                            <CheckBoxInput
                                label={t('propertiesLabel.totalUnitsOnFloor', { ns: 'studentLife', what: floorDetails.propertyType !== 1 ? "rooms" : "units" })}
                                labelId='totalUnitsOnFloor'
                                formikValue={formik.values.totalUnitsOnFloor}
                                formikChange={handleChange}
                                isDoubled
                                checked={formik.values.totalUnitsOnFloor}

                            />
                            <CheckBoxInput
                                label={t('propertiesLabel.gender')}
                                labelId='gender'
                                formikValue={formik.values.gender}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.gender}
                            />
                            <CheckBoxInput
                                label={t('propertiesLabel.program')}
                                labelId='program'
                                formikValue={formik.values.program}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.program}
                            />
                            <CheckBoxInput
                                label={t('propertiesLabel.transfers')}
                                labelId='transfers'
                                formikValue={formik.values.transfers}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.transfers}
                            />
                        </FormGroup>
                        <FormGroup style={{ padding:"20px" }}>
                            <CheckBoxInput
                                label={t('propertiesLabel.amenities')}
                                labelId='amenities'
                                formikValue={formik.values.amenities}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.amenities}
                            />
                            <CheckBoxInput
                                label={t('propertiesLabel.yearClassification')}
                                labelId='yearClassification'
                                formikValue={formik.values.yearClassification}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.yearClassification}
                            />
                            <CheckBoxInput
                                label={t('GPA')}
                                labelId='gpa'
                                formikValue={formik.values.gpa}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.gpa}

                            />
                            <CheckBoxInput
                                label={t('Age')}
                                labelId='age'
                                formikValue={formik.values.age}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.age}

                            />
                        </FormGroup>
                        <FormGroup style={{ padding:"20px" }}>
                            <CheckBoxInput
                                label={t('propertiesLabel.athletics')}
                                labelId='athletics'
                                formikValue={formik.values.athletics}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.athletics}

                            />
                            <CheckBoxInput
                                label={t('propertiesLabel.wheelchairAccessible')}
                                labelId='wheelchairAccess'
                                formikValue={formik.values.wheelchairAccess}
                                formikChange={formik.handleChange}
                                isDoubled
                                checked={formik.values.wheelchairAccess}

                            />
                        </FormGroup>
                        <hr></hr>
                        <FormGroup></FormGroup>
                        <FormGroup>
                            <ButtonWrapperFilter>
                            <Button onClick={() => { dispatch(updateFormStatus(false)); onClose(); }} aria-label={'Cancel'} className="BgLightBlue" variant="contained" size="large">
                                    {t('buttonLabel.cancel')}
                                </Button>
                                <Button 
                                disabled={!(floorValuesChecked && dropDownValuesCheck)}
                                aria-label={'Copy'} className='BgYellowOrange' variant="contained" size="large" type="submit" >
                                    {t('buttonLabel.copy')}
                                </Button>
                              
                            </ButtonWrapperFilter>
                        </FormGroup>
                    </CardContent>
                </Card>
            </form>

        </>
    )
}

export default CopyFloor;


