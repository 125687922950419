import { combineReducers } from 'redux';
import addressReducer from './admisssion/prospects/addAddress/reducer';
import highSchoolReducer from './admisssion/prospects/addHighSchool/reducer';
import testScoreReducer from './admisssion/prospects/addTestScore/reducer';
import searchProspectsReducer from './admisssion/prospects/search-prospects/reducer';
import updateTabValueReducer from './common/tabValueChange/reducer';
import activityReducer from './admisssion/prospects/addActivity/reducer';
import interestReducer from './admisssion/prospects/addInterest/reducer';
import collegeReducer from './admisssion/prospects/addCollege/reducer';
import updateFormStatusReducer from './common/formValueChange/reducer';
import prospectReducer from './admisssion/prospects/addProspects/reducer';
import updateUserReducer from './common/login/reducer';
import contactReducer from './admisssion/prospects/addContact/reducer';
import prevNameReducer from './admisssion/prospects/addPrevName/reducer';
import awardReducer from './admisssion/prospects/addAward/reducer';
import identifierReducer from './admisssion/prospects/addIdentifier/reducer';
import ethnicityReducer from './admisssion/prospects/addEthnicity/reducer';
import employmentReducer from './admisssion/prospects/addEmployment/reducer';
import searchDataReducer from './common/search-data/reducer';
import citizenshipReducer from './admisssion/prospects/addCitizenship/reducer';
import degreeReducer from './admisssion/prospects/addDegree/reducer';
import FERPAReducer from './admisssion/prospects/addFerpa/reducer';
import GPAGroupReducer from './admisssion/prospects/addGPAGroup/reducer';
import RelationsReducer from './admisssion/prospects/addRelations/reducer';
import questionReducer from './admisssion/prospects/addQuestions/reducer';
import transcriptReducer from './registration/academic/transcript/reducer';
import lookupsHighSchoolReducer from './admisssion/lookups/addHighSchool/reducer';
import transferReducer from './registration/academic/transfer/reducer'
import updateTableReducer from './common/tableTracker/reducer'
import degreeAuditReducer from './admisssion/prospects/addDegreeAudit/reducer';
import gradesReducer from './registration/academic/grades/reducer';
import offeringMaintenanceReducer from './registration/offerings/offeringMaintanence/reducer';
import unofficialReducer from './registration/academic/unofficial/reducer';
import scheduleReducer from './registration/academic/schedule/reducer';
import massStudentRegistrationReducer from './registration/massStudentRegistration/reducer';
import masterCourseReducer from './registration/masterCourses/reducer';
import costCenterReducer from './registration/offerings/tutionRefund/reducer';
import dataEntryReducer from './registration/attendance/dataEntry/reducer';
import dataEntryRangeReducer from './registration/attendance/dataRangeEntry/reducer';
import batchReducer from './billing/batch/reducer'
import authNamesReducer from './billing/authorize/reducer';
import alterPlanReducer from './billing/paymentplans/alterplan/reducer';
import accountingReducer from "./billing/accounting/reducer";
import maintananceReducer from './billing/maintanance/reducer';
import maintananceFinancialReducer from './financialAid/workstudy-maintenance/reducer'
import workStudySetupReducer from './financialAid/setup/workStudySetup/reducer';
import yearlySetupReducer from './financialAid/setup/yearlySetup/reducer';
import loadPellReducer from './financialAid/setup/loadPell/reducer';
import loadProspectsReducer from './admisssion/prospects/load-prospects/reducer';
import balanceReducer from './billing/balance/reducer';
import termReducer from './financialAid/transfer/reducer';
import studentStatusReducer from './financialAid/studentStatus/reducer';
import singlePackageReducer from './financialAid/packaging/single/reducer';
import userRoleAdminstrationReducer from './faculty-manager/users/role-adminstration/reducer'
import userAdminstrationReducer from './faculty-manager/users/user-administration/reducer';
import budgetReducer from './financialAid/packaging/prioritized/budgets/reducer';
import packageReducer from './financialAid/packaging/prioritized/package/reducer';
import billingProcessInterestReducer from './tools/processes/billing-processes/reducer';
import latePaymentFineReducer from './tools/processes/late-payment-fees/reducer';
import saveLifetimeReducer from './financialAid/packaging/lifetime/reducer';
import customGradeReducer from './faculty-manager/lookup-table-options/custom-grades/reducer';
import locationLookupReducer from './faculty-manager/lookup-table-options/location-lookup/reducer';
import massAddAuditReducer from './tools/processes/mass-add-audit/reducer';
import crsEvalMasterReducer from './tools/CRSEvalMaster/mass-add-audit/reducer';
import studentReducer from './faculty-manager/CAMS-Portal/Portal-Configuration/student-configuration/reducer';
import addStudentActivityReducer from './tools/admission/studentActivity/reducer';
import sapChangesReducer from './tools/processes/sap-changes/reducer';
import criteriaTabReducer from './tools/import/financial-aid/isir-direct/reducer';
import addProspectActivityReducer from './tools/admission/prospectActivity/reducer';
import statusChangesReducer from './tools/processes/status-changes/reducer';
import codexportReducer from './tools/export/financial-aid/cod-export/reducer';
import nslcReducer from './tools/export/financial-aid/nslc-export/reducer';
import degreeVerifyReducer from './tools/export/registration/degreeVerify/reducer';
import powerfaidExportReducer from './tools/export/financial-aid/powerfaids/reducer';
import speedeCollegeReducer from './tools/export/registration/speedeCollege/reducer';
import importPowerFaidsReducer from './tools/import/financial-aid/power-Faids/reducer'
import texasStateReportReducer from './tools/export/texas-state-reports/successinit/reducer';
import importSpeedeCollegeReducer from './tools/import/speede-college/reducer';
import edeAwardsReducer from './tools/import/financial-aid/ede-awards/reducer';
import testScoreIpmortReducer from './tools/import/admission/testscore-import/reducer';
import prospectStudentIpmortReducer from './tools/import/admission/prospect-student-import/reducer';
import updateAccessReducer from './common/roleAccess/reducer';
import searchFacultyReducer from './faculty-manager/faculty-maintenance/search-faculty/reducer';
import facultyAddressReducer from './faculty-manager/faculty-maintenance/addAddress/reducer';
import searchDataFacultyReducer from './faculty-manager/faculty-maintenance/search-data/reducer';
import updateT2202FormStatusReducer from './common/ReportHTML/Billing/T2202/reducer';
import updateT4AFormStatusReducer from './billing/reportMenu/T4AForm/reducer';
import updateEnrollmentReducer from './financialAid/report/statusAndLoad/enrollment/reducer';
import awardLetterReducer from './financialAid/report/awardLetter/reducer';
import Form1098TReducer from './billing/reportMenu/1098TForm/reducer';
import integrationPlatformReducer from './faculty-manager/integration-platform/reducer';
import gradesReportReducer from './registration/academic/Reports/grade-reports/reducer';
import courseManagementRolesReducer from './faculty-manager/CAMS-Portal/Course-Management-Roles/reducer';
import statementsReducer from './billing/reportMenu/statements/reducer';
import criteriReportReducer from './admisssion/studentReports/workingToDo/reducer';
import amenitiesReducer from './student-life-page/amenities/reducer';
import propertiesReducer from './student-life-page/properties/reducer';
import mealPlanReducer from './student-life-page/mealPlan/reducer';
import housingPeriodsReducer from "./student-life-page/housing-periods/housingPeriodsReducer";
import housingPeriodPropertyReducer from "./student-life-page/housing-period-property/housingPeriodPropertyReducer";
import ipedsReducer from './faculty-manager/faculty-maintenance/education/reducer';

//combined reducer
const rootReducer = combineReducers({
    allProspects: searchProspectsReducer,
    address: addressReducer,
    highSchool: highSchoolReducer,
    testScore: testScoreReducer,
    tabValue: updateTabValueReducer,
    formStatus: updateFormStatusReducer,
    activity: activityReducer,
    interest: interestReducer,
    college: collegeReducer,
    prospect: prospectReducer,
    user: updateUserReducer,
    contact: contactReducer,
    prevName: prevNameReducer,
    award: awardReducer,
    identifier: identifierReducer,
    ethnicity: ethnicityReducer,
    employment: employmentReducer,
    allData: searchDataReducer,
    citizenship: citizenshipReducer,
    degree: degreeReducer,
    ferpa: FERPAReducer,
    gpaGroup: GPAGroupReducer,
    relations: RelationsReducer,
    prospectQuestions: questionReducer,
    transcript: transcriptReducer,
    lookupsHs: lookupsHighSchoolReducer,
    transfer: transferReducer,
    table: updateTableReducer,
    gradeData: gradesReducer,
    degreeAudit: degreeAuditReducer,
    offeringMaintenance: offeringMaintenanceReducer,
    unofficial: unofficialReducer,
    masterList: masterCourseReducer,
    register: massStudentRegistrationReducer,
    costCenter: costCenterReducer,
    dataEntry: dataEntryReducer,
    schedule: scheduleReducer,
    dataRangeEntry: dataEntryRangeReducer,
    batch: batchReducer,
    alterPlan: alterPlanReducer,
    recreate: accountingReducer,
    maintananceData: maintananceReducer,
    authNames: authNamesReducer,
    maintenance: maintananceFinancialReducer,
    workStudySetup: workStudySetupReducer,
    yearlySetup: yearlySetupReducer,
    loadPell: loadPellReducer,
    loadProspects: loadProspectsReducer,
    balance: balanceReducer,
    studentStatus: studentStatusReducer,
    singlePackage: singlePackageReducer,
    roleAdminstration: userRoleAdminstrationReducer,
    userAdminstration: userAdminstrationReducer,
    term: termReducer,
    budget: budgetReducer,
    PackagingData: packageReducer,
    billingProcessInterest: billingProcessInterestReducer,
    latePaymentFine: latePaymentFineReducer,
    packagingLifetime: saveLifetimeReducer,
    customGrade: customGradeReducer,
    locationLookup: locationLookupReducer,
    massAddAudit: massAddAuditReducer,
    crsevalmaster: crsEvalMasterReducer,
    student2: studentReducer,
    studentActivity: addStudentActivityReducer,
    sapChanges: sapChangesReducer,
    isirdirect: criteriaTabReducer,
    prospectActivity: addProspectActivityReducer,
    statusChanges: statusChangesReducer,
    codexport: codexportReducer,
    nslc: nslcReducer,
    degreeVerify: degreeVerifyReducer,
    powerfaidExport: powerfaidExportReducer,
    speedeData: speedeCollegeReducer,
    powerFaidsImport: importPowerFaidsReducer,
    successInitData: texasStateReportReducer,
    speedeCollege: importSpeedeCollegeReducer,
    edeAwards: edeAwardsReducer,
    testScoreImport: testScoreIpmortReducer,
    prospectStudentImport: prospectStudentIpmortReducer,
    access: updateAccessReducer,
    allFaculty: searchFacultyReducer,
    facultyAddress: facultyAddressReducer,
    allFacultyData: searchDataFacultyReducer,
    formValues: updateT2202FormStatusReducer,
    t4AForm: updateT4AFormStatusReducer,
    enrollValue: updateEnrollmentReducer,
    awardLetter: awardLetterReducer,
    Form1098T: Form1098TReducer,
    integrationPlatform: integrationPlatformReducer,
    gradeReportData: gradesReportReducer,
    courseManagement: courseManagementRolesReducer,
    statement: statementsReducer,
    ipeds: ipedsReducer,
    workingToDoReportData: criteriReportReducer,
    amenities:amenitiesReducer,
    properties:propertiesReducer,
    mealPlan: mealPlanReducer,    
    housingPeriods: housingPeriodsReducer,
    housingPeriodProperty: housingPeriodPropertyReducer
})

export default rootReducer;