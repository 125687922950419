export const convertBooleanToYesNo = (bool) => {
    return bool ? "Yes" : "No";
}

export const convertISODateTimeStringToShortDate = (isoDateTimeString) => {
    const datetime = new Date(isoDateTimeString);
    const year = datetime.getFullYear();
    const month = (1 + datetime.getMonth()).toString().padStart(2, '0');
    const day = datetime.getDate().toString().padStart(2, '0');

    return `${month}/${day}/${year}`;
}