import React from 'react';
import { Dialog, DialogContent, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import DialogActions from '@mui/material/DialogActions';
import { FormGroup, ButtonWrapperFilter, useStyles } from '../../../../styles/common-styles';
import * as yup from 'yup';
import { Button } from '@material-ui/core';
import { SelectInput, TextAreaInput } from '../../../../components/Forms';
import { useFormik } from 'formik';
import { postApi } from '../../../../services/apiBase';
import { housingAPIs } from '../../../../services/apiURL';
import { toastMessage } from '../../../../utils/Message/toasts';
import CloseIcon from '@material-ui/icons/Close';

const ActionDialog = ({open,onClose,residentTypes,applicationReqs,actionStatus}) => {
       
    const classes = useStyles();
    
    const { t } = useTranslation(["studentLife"]);
    const residentialTypeLabel = t("housingPeriods.processapplication.search.residentialType", {ns:"studentLife"})
    const reasonLabel = t("housingPeriods.processapplication.search.reason", {ns:"studentLife"});
    const updateButtonLabel = t("buttonLabel.update");
    const closeButtonLabel = t("buttonLabel.cancel");
    const reasonRejectHeading = t("housingPeriods.processapplication.search.rejectHeading", {ns:"studentLife"})
    const reasonCancelHeading = t("housingPeriods.processapplication.search.cancelHeading", {ns:"studentLife"})
    const approveUpdateField = t("housingPeriods.processapplication.search.approveUpdateField", {ns:"studentLife"})
    const noLabel = t("buttonLabel.no", {ns:"studentLife"})
    const yesLabel = t("buttonLabel.yes", {ns:"studentLife"})
 

    const validateReason = yup.object({
        reason: yup
            .string()
            .required(t('validationMessages.isRequired', { what: reasonLabel }))
    });

    const validateResidenttype = yup.object({
        residentialType: yup
            .string()
            .required(t('validationMessages.isRequired', { what: residentialTypeLabel }))
    });
    
    const processApplications = async () => {
        const payload = {
            submissionIDs: applicationReqs.map(s => s.submissionID),
            status: actionStatus,
            residentTypeID: actionStatus === 1 ? formik.values.residentialType : null,
            reason: actionStatus > 1 ? formik.values.reason : null
        };

        try {
            const resp = await postApi(`${housingAPIs.housingPeriods.properties.process}/0/action`, payload);
            if (resp.data.isSuccess) {
                toastMessage(t('popupMessages.successIcon'), t('popupMessages.success'), t('popupMessages.successfullySaved'));
            } else {
                toastMessage(t('popupMessages.warningIcon'), t('popupMessages.error'), resp.data.data);
            }
        }
        catch (err) {
            console.error(err);
        }
        finally {
            formik.resetForm();
            onClose();
        }
    }

    const onNoClick = async () => {
        formik.values.residentialType = null;
        await processApplications();
    }

    const formik = useFormik({
        initialValues: {residentialType:undefined, reason:''},
        validationSchema: actionStatus >1 ? validateReason : validateResidenttype,
        enableReinitialize: true,
        onSubmit: async () => {
            await processApplications();
        }
    });

    const onCloseDialog = () => {
        formik.resetForm();
        onClose(true);
    }

    const showResidentType = actionStatus === 1;
    const showReason = actionStatus > 1;
    const showNoButton = actionStatus === 1;
    const dialogHeader = showResidentType
        ? approveUpdateField
        : actionStatus === 3
            ? reasonRejectHeading
            : reasonCancelHeading;
    const yesButtonLabel = actionStatus > 1
        ? updateButtonLabel
        : yesLabel;

    return (
        
        <Dialog 
            open={open}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth={true}
            aria-labelledby="customized-dialog-title"
        >
            <form autoComplete="off" className={classes.root} onSubmit={formik.handleSubmit}>
                <DialogContent  id="responsive-dialog-title">

                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>     
                    <Typography component="h2" variant="h7" style={{textAlign:'center',margin: "20px 0px 30px 0px", fontSize: "larger" }}>{dialogHeader}</Typography>
                    <IconButton
                        aria-label="close"
                        onClick={onClose}
                        style={{
                            float: "right",
                            marginTop: -50,
                            textAlign: "right",
                            marginRight: -20
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </div>



                    {showResidentType &&
                        <FormGroup>
                            <SelectInput
                                label={residentialTypeLabel}
                                labelId={"residentialType"}
                                aria-label={residentialTypeLabel}
                                formikValue={formik.values.residentialType}
                                formikChange={formik.handleChange}
                                errors={formik.errors.residentialType}
                                touched={formik.errors.residentialType}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: residentTypes }}
                                customStyle={{
                                    width: "100%",
                                }}
                            />
                        </FormGroup>
                    }                                        

                    {showReason && 
                        <FormGroup>
                            <TextAreaInput
                                mandatory
                                label={reasonLabel}
                                labelId={"reason"}
                                aria-label={reasonLabel}
                                formikValue={formik.values.reason}
                                errors={formik.errors.reason}
                                touched={formik.errors.reason}
                                formikChange={formik.handleChange}
                                customStyle={{
                                    width: "100%",
                                }}
                                maxlength={255}
                                required
                            />
                        </FormGroup>
                    }
                </DialogContent>

                <DialogActions>
                    <ButtonWrapperFilter>
                        <Button 
                            className="BgLightBlue search"
                            variant="contained"
                            size="large"
                            type="reset"
                            aria-label={closeButtonLabel}
                            onClick={onCloseDialog}
                        >
                            {closeButtonLabel}                        
                        </Button>                             

                        {showNoButton &&                    
                            <Button 
                                className="BgLightBlue search"
                                variant="contained"
                                size="large"
                                type="reset"                        
                                aria-label={noLabel}
                                onClick={onNoClick}
                            >
                                {noLabel}                                 
                            </Button>
                        }

                        <Button                    
                            className="BgYellowOrange search"
                            variant="contained"
                            size="large"
                            type="submit"
                            aria-label={yesButtonLabel}
                        >
                            {yesButtonLabel}
                        </Button>
                    </ButtonWrapperFilter>          
                </DialogActions>
            </form>
        </Dialog>
    )
}
export default React.memo(ActionDialog);
