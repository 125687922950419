import { Button, Card, CardContent } from "@material-ui/core";
import ElementsDataGridPro from "../../../../utils/DataGrid/ElementsDataGridPro";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { selectHousingPeriod, selectProcessAppliationsPermissions, selectSearchHousingPeriodsResults } from "../../../../redux/student-life-page/housing-periods/housingPeriodsSelectors";
import { useDispatch, useSelector } from "react-redux";
import { TextInput,SelectInput, MultipleSelectInput } from "../../../../components/Forms";
import { useFormik } from "formik";
import { clearHousingPeriod, loadHousingPeriodRoomAssignments, searchHousingPeriodRoomAssignments } from "../../../../redux/student-life-page/housing-periods/housingPeriodsActions";
import { ButtonWrapperFilter, FormGroup, Splitter, useStyles } from "../../../../styles/common-styles";
import { getApi, postApi } from "../../../../services/apiBase";
import { formsAPIs, housingAPIs } from "../../../../services/apiURL";
import { Divider, Typography } from "@mui/material";
import ActionDialog from "./actionDialog";
import Formdialog from "../../../tools-page/forms/components/formdialog";

export const HousingPeriodRoomAssignmentGrid = () => {

    const { housingPeriodID }= useSelector(selectHousingPeriod);

    // Labels
    const { programs,  housingApplications,campuses, residentialStatus , residentialTypes } = useSelector((state) => state.housingPeriods.selectItems);
    const { t } = useTranslation(["studentLife"]);
    const applicationNameLabel = t("housingPeriods.processapplication.search.applicationName", {ns:"studentLife"});
    const studentId = t("housingPeriods.processapplication.search.studentId", {ns:"studentLife"})

    const searchButtonLabel = t("buttonLabel.search");
    const cancelButtonLabel = t("buttonLabel.cancel");
    const resetButtonLabel = t("buttonLabel.reset");
    
    const residentialStatusLabel = t("housingPeriods.processapplication.search.residentialStatus", {ns:"studentLife"})
    const residentialTypeLabel = t("housingPeriods.processapplication.search.residentialType", {ns:"studentLife"})
    const campusLabel = t("housingPeriods.processapplication.search.campus", { ns: "studentLife" });
    const firstNameLabel = t("housingPeriods.processapplication.search.firstName", { ns: "studentLife" });
    const lastNameLabel = t("housingPeriods.processapplication.search.lastName", { ns: "studentLife" });
    const programLabel = t("housingPeriods.processapplication.search.program", {ns:"studentLife"})
    const propertyLabel =t("housingPeriods.processapplication.search.property", {ns:"studentLife"})
    const roomAssignedLabel = t("housingPeriods.processapplication.search.roomAssigned", {ns:"studentLife"})
    const ageLabel = t("housingPeriods.processapplication.search.age", {ns:"studentLife"});
    const selectOneLabel = t("housingPeriods.addEditWizard.basicDetails.selectOneLabel", {ns:"studentLife"});
    const searchResultsTitle = t("housingPeriods.roomAssignment.results", { ns: "studentLife" });

   useEffect(() =>{
        dispatch(searchHousingPeriodRoomAssignments(getPayload()));
   },[])
    const resetForm = async () => {
        formik.resetForm();
        dispatch(searchHousingPeriodRoomAssignments(getPayload()));
    }

    
    const  housingPeriodApplications = useSelector((state) => state.housingPeriods.housingPeriodApplications);    

    const classes = useStyles();

    const isLoading = useSelector((state) => state.housingPeriods.search.isLoading);

    const accessLevel = useSelector(selectProcessAppliationsPermissions);
    const [properties,setProperties] = useState([]);
    
    const [selectedRows, setSelectedRows] = useState([]);
    const processApplicationAccess = useSelector(selectProcessAppliationsPermissions);
    const dispatch = useDispatch();
    const [moreActionsButtonElement, setMoreActionsButtonElement] = useState(null);
    const [approveDialogElement, setCloseActionDialog] = useState(null);
    const [responseDialogElement, setCloseResponseDialog] = useState(null);
    const [formJson, setFormJson] = useState(null);
    const [response, setResponse] = useState(null);

    const open = Boolean(moreActionsButtonElement);
    const openAction = Boolean(approveDialogElement);
    const viewResponse = Boolean(responseDialogElement);
    let superUser = localStorage.getItem('superUser')
    let campusID = localStorage.getItem('campusID')
    const [actionStatus ,setActionStatus] = useState(1);

    const defaultProperties = {"propertyName":"","streetAddress":"","amenityIDs":[],"propertyTypeID":0};
    useEffect(() => {
        postApi(`${housingAPIs.property.search}`,defaultProperties).then(resp => {
            if(resp.data.isSuccess) {
                setProperties(resp.data.data.map(p => { return {"uniqueId":p.propertyTypeID , "displayText": p.propertyName}}));
            }
        });        

    }, []);

    const residentialStatusWithEmptyValue = [
        { uniqueId: '-1', displayText: selectOneLabel, isSelected: false },
        ...residentialStatus
    ];

    const programsWithEmptyValue = [
        { uniqueId: '-1', displayText: selectOneLabel, isSelected: false },
        ...programs
    ];

    const propertiesWithEmptyValue = [
        { uniqueId: '-1', displayText: selectOneLabel, isSelected: false },
        ...properties
    ];

    const roomAssignedDropdown = [
        { "uniqueId": '1', "displayText": "No", "isSelected": true },
        { "uniqueId": '2', "displayText": "Yes", "isSelected": false },
    ]

    const defaultCriteria = {
        residentialStatusID: '1',
        "campusId": (superUser === 'N') ? Number(campusID) : "0",
        housingPeriodID: housingPeriodID,
        properties: '-1',
        property: '-1',
        residentStatusId: '-1',
        formID: housingApplications ? housingApplications[0].id : undefined,
        roomAssigned: '1',
        programId: '0',
        ageOperator: ''
    }; 

    const getPayload = () => {
        return{
        housingPeriodID: housingPeriodID,
        formID: formik.values.formID,
        applicationStatusID: formik.values.applicationStatusId,
        residentStatusID: formik.values.residentStatusId === '-1' ? undefined : formik.values.residentStatusId,
        campusID: formik.values.campusId === '-1' ? undefined : formik.values.campusId,
        programID: formik.values.programId === '0' ? undefined : formik.values.programId,
        properties: formik.values.properties === '-1' ? undefined : formik.values.properties,
        ageOperator: formik.values.ageOperator,
        age: !formik.values.age ? null : formik.values.ageOperator !== "Between" ? [formik.values.age] : [formik.values.ageLowerLimit, formik.values.ageUpperLimit],
        roomAssigned: formik.values.roomAssigned
        };
    };
    const formik = useFormik({
        initialValues: defaultCriteria,
        enableReinitialize: true,
        onSubmit: () => {
            dispatch(searchHousingPeriodRoomAssignments(getPayload()));
        }
    });

    const arithmeticDropdown = [
        {
            "uniqueId": "Equal",
            "displayText": "=",
            "isSelected": false
        }
        ,
        {
            "uniqueId": "GreaterThan",
            "displayText": ">",
            "isSelected": false
        }
        ,
        {
            "uniqueId": "LessThan",
            "displayText": "<",
            "isSelected": false
        }
        ,
        {
            "uniqueId": "GreaterThanOrEqual",
            "displayText": ">=",
            "isSelected": false
        }
        ,
        {
            "uniqueId": "LessThanOrEqual",
            "displayText": "<=",
            "isSelected": false
        }
        ,
        {
            "uniqueId": "Between",
            "displayText": "Between",
            "isSelected": true
        }
    ];

    const housingRoomAssignmentColumns = [
        { id: "applicationName", label: applicationNameLabel },
        { id: "lastName", label: lastNameLabel },
        { id: "firstName", label: firstNameLabel },
        { id: "studentId",  label: studentId },
        { id: "residentType",  label: residentialTypeLabel},
        { id: "roomAssigned", label: roomAssignedLabel },
        { id: "residentStatus", label: residentialStatusLabel },
    ];
    
    const housingPeriodApprovedApplications  = useSelector(selectSearchHousingPeriodsResults);

    const closeApproveDialog = ()=>{
        setCloseActionDialog(null);
        dispatch(loadHousingPeriodRoomAssignments(formik.values));
    }

    const closeResponseDialog = ()=>{
        setCloseResponseDialog(null);
    }


    const openActionDialog = () => {
        setCloseActionDialog(selectedRows.length>0);
    }

    const viewResponseDialog = (selectedRow) => {
        
        const formUID = selectedRow.formUID;
        const responseId = selectedRow.responseId;

        getApi(`${formsAPIs.get}/${formUID}`)
        .then(response => {
            if(response.data.isSuccess) {
                setFormJson(JSON.parse(response.data.data.formQuestionsJson));
            }
        })


        getApi(`${formsAPIs.response}/${formUID}/${responseId}`)
        .then(response => {
            if(response.data.isSuccess) {
                setResponse(response.data.data);
            }
        })

        setCloseResponseDialog(response!==undefined);
    }

    const initialState ={
        sorting: {
            sortModel: [{ field: 'applicationName', sort: 'asc' }],
            sortModel: [{ field: 'applicationStatus', sort: 'asc' }],
        },
        columns: {
            columnVisibilityModel: {
                residentType: true,
            },
        },
    }

    const handleCancelClick = () => {
        dispatch(clearHousingPeriod());
    }

return (
        <>
            <Card className={classes.root}>
                <CardContent>
                    <Typography component="h3" variant="h7" style={{ marginBottom: "10px", fontSize: "larger" }}>{t("housingPeriods.assignSearchpageTitle")}</Typography>
                    <br/> 
                    <form onSubmit={formik.handleSubmit} autoComplete="off">
                        <FormGroup>
                        <SelectInput labelId={"formID"}
                                label={applicationNameLabel}
                                mandatory={true}
                                aria-label={applicationNameLabel}
                                formikValue={formik.values.formID}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "applicationName", value: 'id', optionLists: housingApplications }}
                                onTooltip={t('roomAssignmentTooltip.applicationName')}
                            ></SelectInput>
                            <SelectInput labelId={"residentStatusId"}
                                label={residentialStatusLabel}
                                aria-label={residentialStatusLabel}
                                formikValue={formik.values.residentStatusId}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: residentialStatusWithEmptyValue }}
                            ></SelectInput>
                            <SelectInput labelId={"campusId"}
                                label={campusLabel}
                                aria-label={campusLabel}
                                formikValue={formik.values.campusId}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: campuses }}
                                onTooltip={t('roomAssignmentTooltip.campus')}
                            ></SelectInput>
                            <SelectInput labelId={"programId"}
                                label={programLabel}
                                aria-label={programLabel}
                                formikValue={formik.values.programId}
                                formikChange={formik.handleChange}
                                dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: programs }}
                                onTooltip={t('roomAssignmentTooltip.program')}
                            ></SelectInput> 
                        </FormGroup>
                            <FormGroup>
                                <SelectInput 
                                    labelId={"property"}
                                    label={propertyLabel}                                
                                    aria-label={propertyLabel}                                
                                    formikValue={formik.values.property}
                                    formikChange={formik.handleChange}
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: propertiesWithEmptyValue }}
                                    onTooltip={t('roomAssignmentTooltip.property')}
                                ></SelectInput>
                                {/* <SelectInput 
                                    labelId={"roomAssigned"}
                                    label={roomAssignedLabel}                                
                                    aria-label={roomAssignedLabel}                                
                                    formikValue={formik.values.roomAssigned}
                                    formikChange={formik.handleChange}
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: roomAssignedDropdown }}
                                    onTooltip={t('roomAssignmentTooltip.roomAssigned')}
                                ></SelectInput>       */}
                                <SelectInput
                                    label={ageLabel}
                                    labelId={"ageOperator"}
                                    customStyle={{
                                        width: "184px",
                                        
                                    }}
                                    dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: arithmeticDropdown }}
                                    formikValue={formik.values.ageOperator}
                                    formikChange={formik.handleChange}
                                    onTooltip={t('roomAssignmentTooltip.ageOperator')}
                                    sortAsc
                                    SoValueEmpty
                                />
                                {formik.values.ageOperator !== "Between" ?
                                    <TextInput
                                        onlyNumberDigits
                                        labelId={"age"}
                                        customStyle={{
                                            width: "64px",
                                            
                                        }}
                                        errors={formik.errors.age}
                                        touched={formik.touched.age}
                                        formikValue={formik.values.age}
                                        formikChange={formik.handleChange}
                                        maxlength={5}
                                        onTooltip={t('roomTooltip.age')}
                                    />
                                    : <>
                                        <TextInput
                                            onlyNumberDigits
                                            labelId={"ageLowerLimit"}
                                            customStyle={{
                                                marginRight: "20px",
                                                width: "64px",
                                            }}

                                            errors={formik.errors.ageLowerLimit}
                                            touched={formik.errors.ageLowerLimit}
                                            formikValue={formik.errors.ageLowerLimit}
                                            formikChange={formik.handleChange}
                                            maxlength={5}
                                            onTooltip={t('roomTooltip.ageLowerLimit')}
                                        />
                                        <TextInput
                                            onlyNumberDigits
                                            labelId={"ageUpperLimit"}
                                            customStyle={{
                                                width: "64px",
                                            }}
                                            errors={formik.errors.ageUpperLimit}
                                            touched={formik.touched.ageUpperLimit}
                                            formikValue={formik.values.ageUpperLimit}
                                            formikChange={formik.handleChange}
                                            maxlength={5}
                                            onTooltip={t('roomTooltip.ageUpperLimit')}
                                        /></>

                                }                            
                        </FormGroup>
                        <ButtonWrapperFilter>
                            <Button
                                className="BgYellowOrange search"
                                variant="contained"
                                size="large"
                                type="submit"
                                aria-label={searchButtonLabel}
                            >
                                {searchButtonLabel}                                 
                            </Button>
                            <Button
                                className="BgLightBlue search"
                                variant="contained"
                                size="large"
                                type="reset"
                                aria-label={resetButtonLabel}
                                onClick={resetForm}
                            >
                                {resetButtonLabel}
                            </Button>
                        </ButtonWrapperFilter>
                    </form>
            </CardContent>
            <Splitter height="20px" />
            <Divider />
            <Splitter height="20px" />
            <CardContent>
                     <Typography component="h3" variant="h7" style={{ marginBottom: "10px", fontSize: "larger" }}>{searchResultsTitle}</Typography>
                     <ElementsDataGridPro                    
                        defaultColumns={{ residentType: false }}
                        trackingLabel={"housingPeriodsAssignRoomsTable"}
                        headCells={housingRoomAssignmentColumns}
                        rows={housingPeriodApprovedApplications}                        
                        onView={viewResponseDialog}
                        isLookup={false}
                        isLoading={isLoading}
                        pagination={housingPeriodApprovedApplications.length > 0}
                        dataGridToolbar={true}
                        dataGridColumnMenus={true}
                        hideFooterSelectedRowCount={true}
                        initialSorting={initialState} 
                        addEditAccess={accessLevel === 2}
                        viewAccess={accessLevel >= 1}
                        multiSelect={true}
                        moreActionsAccess = { selectedRows.length >0 && accessLevel===2 &&  processApplicationAccess >=1}
                        columnVisibilityModel={initialState.columns.columnVisibilityModel}
                        selectionModelChanged={(rows)=>setSelectedRows(rows)}
                    />
                    <br/>
                    <ButtonWrapperFilter>
                        <Button
                            className="BgLightBlue search"
                            variant="contained"
                            size="large"
                            type="reset"
                            aria-label={ cancelButtonLabel}
                            onClick={handleCancelClick}
                        >
                            {cancelButtonLabel}
                        </Button>
                    </ButtonWrapperFilter>
                </CardContent>
            </Card>            
                                
            <ActionDialog open={openAction} onClose={closeApproveDialog} residentTypes={residentialTypes} applicationReqs={selectedRows} actionStatus={actionStatus} ></ActionDialog>
            
            {viewResponse &&  <Formdialog open={viewResponse} onClose={closeResponseDialog} formData={{jsonObj:formJson,formResult:response}} />}
    </>
    );


}