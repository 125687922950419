import {
    LOAD_HOUSING_PERIOD_PROPERTY,
    LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS,
    LOAD_HOUSING_PERIOD_PROPERTY_ERROR,
    SET_SELECTED_FLOOR,
    SET_SELECTED_UNIT,
    SET_SELECTED_ROOM,
    CLEAR_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY,
    SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS,
    SAVE_HOUSING_PERIOD_PROPERTY_ERROR
} from './housingPeriodPropertyActionTypes';

const initialState = {
    housingPeriodProperty: {
        housingPeriodPropertyID: 0,
        propertyName: "",
        propertyTypeID: null,
        isActive: 1,
        isParkingAvailable: null,
        isTransfers: null,
        isSmokingAllowed: null,
        isWheelchairAccessible: null,
        genderID: null,
        amenityIDs: [],
        programsIDs: [],
        yearClassificationIDs: [],
        glossarySportsIDs: [],
        floors: [],
        units: [],
        rooms: []
    },
    selectedFloor: { amenityIDs: [], programsIDs: [], glossarySportsIDs: [], yearClassificationIDs: [] },
    selectedUnit: {},
    selectedRoom: { amenityIDs: [], programsIDs: [], glossarySportsIDs: [], yearClassificationIDs: [] }
};

const housingPeriodPropertyReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case LOAD_HOUSING_PERIOD_PROPERTY:
        case LOAD_HOUSING_PERIOD_PROPERTY_ERROR:
        case SAVE_HOUSING_PERIOD_PROPERTY:
        case SAVE_HOUSING_PERIOD_PROPERTY_ERROR:
            return { ...state };
        case LOAD_HOUSING_PERIOD_PROPERTY_SUCCESS:
            return {
                ...state,
                housingPeriodProperty: payload.housingPeriodProperty,
                selectedFloor: payload.selectedFloor,
                selectedUnit: payload.selectedUnit,
                selectedRoom: payload.selectedRoom
            };
        case SET_SELECTED_FLOOR:
            return {
                ...state,
                selectedFloor: payload.selectedFloor,
                selectedUnit: payload.selectedUnit,
                selectedRoom: payload.selectedRoom
            }
        case SET_SELECTED_UNIT:
            return {
                ...state,
                selectedUnit: payload.selectedUnit,
                selectedRoom: payload.selectedRoom
            }
        case SET_SELECTED_ROOM:
            return {
                ...state,
                selectedRoom: payload
            }
        case CLEAR_HOUSING_PERIOD_PROPERTY:
            return { ...initialState };

        case SAVE_HOUSING_PERIOD_PROPERTY_SUCCESS:
            return {
                ...state,
                housingPeriodProperty: payload
            }
        default:
            return state;
    }
}

export default housingPeriodPropertyReducer;