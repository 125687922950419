import React, { useState } from 'react';
import { Typography, Button, Grid, makeStyles, Card, CardContent } from '@material-ui/core';
import { useHistory } from 'react-router';
import { ButtonWrapper, ButtonWrapperFilter } from '../../styles/common-styles'
import { updateTabValue } from '../../redux/common/tabValueChange/action';
import { CheckBoxInput } from '../../components/Forms/index';
import { useDispatch } from 'react-redux';
import { addAddress, getAddress } from '../../redux/admisssion/prospects/addAddress/action';
import { getSelectedProfiles } from '../../redux/common/search-data/action';
import config from '../../services/config';
import Tooltip from '@mui/material/Tooltip';
import { ClickAwayListener } from '@mui/material';
import BrandingColors from '../../styles/color';
import EditIcon from '@material-ui/icons/Edit';
import { SelectInput } from "../../components/Forms";

const useStyles = makeStyles((theme) => ({
    root: {
        margin: '20px 0',
        alignItems: 'center',
        '& .MuiTypography-h4': {
            fontFamily: 'Lato-semi-bold',
            fontWeight: 700,
            [theme.breakpoints.down('lg')]: {
                fontSize: '1.5rem',
            },
            "@media (min-width: 1920px)": {
                fontSize: '32px'
            },
            fontSize: '1.5rem',
            lineHeight: '1',
            '&.subHeading': {
                fontFamily: 'Lato-semi-bold',
                fontSize: '1.125rem',
                fontWeight: 600,
                "@media (min-width: 1920px)": {
                    fontSize: '24px'
                },
            }
        },
        '& .titleIcon': {
            borderRadius: "30px",
            color: `${BrandingColors.darkGreyFont}`,
            marginLeft: "6px",
            cursor: "pointer",
            position: "relative",
            top: "5px",
            fontSize: '1.5rem',
            "@media (min-width: 1920px)": {
                fontSize: '32px'
            },
        }
    },
}));

/**
 * @param {{
 * headerTitle: string, 
 * buttonText: string, 
 * buttonText2: string,
 * url: string, 
 * variant: string, 
 * url2: string,
 * help: Boolean, 
 * addData: function 'CB for buttonText', 
 * addDataOne: function 'CB for buttonText2',
 * disabled: String 'disabled',
 * disabled1: String 'disabled1',
 * disableButton: Boolean,
 * checkBoxlabel: String,
 * checkBoxlabelID: String,
 * onCheckBoxClick: function,
 * addCheckBoxClass: String 'Add custom class to adjust checkbox in header',
 * selectInputlabel: String,
 * selectInputlabelID: String,
 * selectDropDownList: Array,
 * onSelectInputClick: function
 * }} props 
 */

const useStylesTooltip = makeStyles((theme) => ({
    root: {
        '& .MuiTooltip-popper': {
            pointerEvents: 'auto',
            inset: "-4px auto auto -12px!important"
        },
        '& .MuiPaper-root': {
            background: 'rgba(97, 97, 97, 0.9)',
            color: '#ffffff',
            fontSize: '14px',
            fontWeight: 400,
            lineHeight: '1.2em',
            fontFamily: 'Lato-regular'
        },
        '& .MuiCardContent-root': {
            textAlign: 'right',
            padding: '12px 20px 20px 20px'
        },
        '& .MuiTooltip-tooltip': {
            padding: 0
        },
        '& .MuiTooltip-arrow': {
            width: '2em',
            height: '1.42em',
            top: '-7px!important',
            left: '11px!important'
        },
        '& .close': {
            cursor: 'pointer'
        },
        '& .tooltipContent': {
            padding: '16px 0px',
            textAlign: 'left',
        },
        '& .more-help': {
            minWidth: '86px',
            height: '36px'
        }
    }
}));

const Icon = (props) => {
    const { handleHelpRedirect } = props
    return (
        <div tabIndex={0} aria-labelledby='help' className="titleIcon material-icons-outlined" title="See more information" onClick={handleHelpRedirect}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    handleHelpRedirect()
                }
            }
            } >
            info
        </div>
    )
}

const TooltipDetail = (props) => {
    const classes = useStylesTooltip();
    const { tooltipContent, url } = props;
    const [open, setOpen] = useState(false);
    const handleTooltipClose = () => {
        setOpen(false);
    };

    // const handleTooltipOpen = () => {
    //     setOpen(true);
    // };

    const handleHelpRedirect = () => {
        window.open((url) ? url : '/SM Lite Built Online Help/Default.htm', '_blank');
    }

    return (
        <span className={classes.root}>
            <Tooltip
                PopperProps={{
                    disablePortal: true,
                }}
                placement={'bottom-start'}
                open={open}
                arrow
                style={{ padding: 0 }}
                disableFocusListener
                disableTouchListener
                disableHoverListener
                title={
                    <>
                        <Card onMouseLeave={handleTooltipClose}>
                            <CardContent>
                                <span className={'close'} onClick={handleTooltipClose}>X</span>
                                <p className='tooltipContent'>
                                    {(tooltipContent) ?
                                        tooltipContent :
                                        "This is the online help tip, Which will take users to a new screen access to desired help documentation. Help is more detailed and robust."}
                                </p>
                                <ButtonWrapperFilter>
                                    <Button
                                        className='BgYellowOrange search more-help'
                                        variant="contained"
                                        size="large"
                                        onClick={handleHelpRedirect}
                                    >
                                        {'More Help'}
                                    </Button>
                                </ButtonWrapperFilter>
                            </CardContent>
                        </Card>
                    </>
                }
            >                
            </Tooltip>
            {Icon({handleHelpRedirect:handleHelpRedirect})}
        </span>
    )
}

const HeaderTitle = (props) => {
    const { headerTitle,
        customeWidth = false,
        customeWidthSize,
        buttonText,
        staticComp,
        buttonText2,
        url,
        variant,
        url2,
        help,
        addData,
        selectInputlabel,
        selectInputlabelID,
        selectDropDownList,
        onSelectInputClick,
        addDataOne,
        editData,
        disabled,
        disabled1,
        checkBoxlabel,
        checkBoxlabelID,
        onCheckBoxClick,
        addCheckBoxClass,
        disableButton,
        disableButtonOne,
        disableUrl,
        disableUrlOne,
        linkHtml,
        plus// for addData, for showing + icon, plus value will be false
        , plus2
    } = props;

    const { clientRoot } = config.ids;

    const history = useHistory();
    const classes = useStyles();
    const dispatch = useDispatch();
    // //Making tab to reset.
    // useEffect(() => {
    //     (buttonText == 'Add Prospect') && dispatch(updateTabValue(0));
    // },[])
    const pushURL = () => {
        if (url === "/admissions/prospects/add-prospects" || url === "/admissions/students/add-students") {
            dispatch(addAddress([]))
            dispatch(getAddress([]))
            dispatch(getSelectedProfiles({}))
        }
        dispatch(updateTabValue(0));
        dispatch(getSelectedProfiles({}))
        history.push(url);
    }

    const urlButtonClasses = plus ? "" : "linkButton";
    const url2ButtonClasses = plus2 ? "" : "linkButton";
    const addDataButtonClasses = [disabled, !plus ? "" : "linkButton"].join(" ");
    const addDataOneButtonClasses = [disabled1 ? "disabled" : "enabled", plus2 ? "" : "linkButton"].join(" ");

    return (
        <Grid container className={classes.root}>
            <Typography tabIndex={0} component={(variant === "subHeading") ? "h2" : "h1"} variant="h4" className={(variant) ? variant : 'mainHeading'} id={(headerTitle) && `headerTitle_${headerTitle.split(' ').join().replace(/,/g, '')}`}>
                {headerTitle}
                {/* {linkHtml ?
                    (help) && <a href={`${clientRoot}${linkHtml}`} target={'_blank'}><span className="titleIcon material-icons-outlined" style={(staticComp) && { color: "red" }}>
                        help
                    </span></a> :
                    (help) && <span className="titleIcon material-icons-outlined" style={(staticComp) && { color: "red" }}>
                        help
                    </span>
                } */}
                {(help) && <TooltipDetail />}
            </Typography>
            {checkBoxlabel &&
                <CheckBoxInput addCheckBoxClass={addCheckBoxClass} label={checkBoxlabel} labelId={checkBoxlabelID} formikChange={(e) => { onCheckBoxClick(e.target.checked) }} />
            }
            <ButtonWrapper className={[disabled, disabled1]}>
                {url &&
                    <Button variant="contained" size="large" type="submit" onClick={pushURL} className={urlButtonClasses} disabled={disableUrl}>
                        {plus && <span className="plus">+</span>}{buttonText}
                    </Button>
                }
                {url2 &&
                    <Button variant="contained" size="large" type="submit" onClick={() => { dispatch(updateTabValue(0)); history.push(url2); }}
                        className={url2ButtonClasses} disabled={disableUrlOne}>
                        {plus2 && <span className="plus">+</span>}{buttonText2}
                    </Button>}
                {addData &&
                    <Button variant="contained" size="large" onClick={() => { addData(true) }} className={addDataButtonClasses} disabled={disableButton} >
                        {!plus && <span className="plus">+</span>}{buttonText}
                    </Button>}
                {addDataOne &&
                    <Button variant="contained" size="large" onClick={() => { addDataOne(true) }} className={addDataOneButtonClasses} disabled={disableButtonOne}>
                        {plus2 && <span className="plus">+</span>}{buttonText2}
                    </Button>}
                {editData &&
                    <Button variant="contained" size="large" onClick={() => { editData(true) }} className={addDataOneButtonClasses} disabled={disableButtonOne}>
                        <EditIcon style={{ color: "#0A66C2", cursor: 'pointer' }} />{buttonText2}
                    </Button>}
                {selectInputlabel &&
                    <SelectInput
                        customStyle={{ backgroundColor: "white", width: customeWidth ? customeWidthSize : "17%", marginRight: "0px" }}
                        label={selectInputlabel}
                        labelId={selectInputlabelID}
                        dropdownData={{ key: "displayText", value: 'uniqueId', optionLists: selectDropDownList }}
                        formikChange={(e) => { onSelectInputClick(e) }}
                        withoutLabel={true}
                    />
                }

            </ButtonWrapper>

        </Grid >
    )
};

export default HeaderTitle;